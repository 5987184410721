import { useAuth } from "../../../../app/modules/auth";
import { KTIcon, toAbsoluteUrl } from "../../../helpers";
import { HeaderUserMenu, Search } from "../../../partials";

/* eslint-disable jsx-a11y/anchor-is-valid */
const AsideToolbar = () => {
  const { currentUser } = useAuth();

  return (
    <>
      {/*begin::User*/}
      <div className="aside-user d-flex align-items-sm-center justify-content-center py-5">
        {/*begin::Symbol*/}

        {/*end::Symbol*/}

        {/*begin::Wrapper*/}
        <div className="aside-user-info flex-row-fluid flex-wrap ms-5">
          {/*begin::Section*/}
          <div className="d-flex">
            {/*begin::Info*/}
            <div className="flex-grow-1 me-2">
              {/*begin::Username*/}
              <a
                href="#"
                className="text-white text-hover-primary fs-6 fw-bold"
              >
                {currentUser?.name}
              </a>
              {/*end::Username*/}

              {/*begin::Description*/}
              <span className="text-gray-600 fw-bold d-block fs-8 mb-1 text-capitalize">
                {currentUser?.role}
              </span>
              {/*end::Description*/}

              {/*begin::Label*/}
              {/* <div className="d-flex align-items-center text-success fs-9">
                <span className="bullet bullet-dot bg-success me-1"></span>
                online
              </div> */}
              {/*end::Label*/}
            </div>
            {/*end::Info*/}

            {/*begin::User menu*/}
            <div className="me-n2">
              {/*begin::Action*/}
              {/* <a
                href="#"
                className="btn btn-icon btn-sm btn-active-color-primary mt-n2"
                data-kt-menu-trigger="click"
                data-kt-menu-placement="bottom-start"
                data-kt-menu-overflow="false"
              >
                <KTIcon iconName="setting-2" className="text-muted fs-1" />
              </a>

              <HeaderUserMenu /> */}
              {/*end::Action*/}
            </div>
            {/*end::User menu*/}
          </div>
          {/*end::Section*/}
        </div>
        {/*end::Wrapper*/}
      </div>
      {/*end::User*/}

      {/*begin::Aside search*/}
      {/* <div className="aside-search py-5">
        
        <Search />
      </div> */}
      {/*end::Aside search*/}
    </>
  );
};

export { AsideToolbar };
