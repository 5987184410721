/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { getUserByToken, login, verifyOtp } from "../core/_requests";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { useAuth } from "../core/Auth";
import toast from "react-hot-toast";

const loginSchema = Yup.object().shape({
  otp: Yup.string()
    .min(4, "Minimum 4 symbols")
    .max(4, "Maximum 4 symbols")
    .required("Otp is required"),
});

const initialValues = {
  otp: "",
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Otp() {
  const location = useLocation();
  const { hash, username }: any = location.state || {
    hash: "",
    username: "",
  };
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const { data }: any = await verifyOtp(hash, username, values.otp);
        if (!data.status) {
          toast.error(data.message);
          setSubmitting(false);
          setLoading(false);
          return;
        }
        saveAuth({
          token: data.token,
        });
        localStorage.setItem("token", data.token);
        if (data.refreshToken)
          localStorage.setItem("refreshToken", data.refreshToken);
        const { data: userData } = await getUserByToken();
        setCurrentUser(userData.user);
        window.location.href = "/";
      } catch (error) {
        console.error(error);
        saveAuth(undefined);
        setStatus("The login details are incorrect");
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  return (
    <form
      className="form w-100"
      onSubmit={formik.handleSubmit}
      noValidate
      id="kt_login_signin_form"
    >
      {/* begin::Heading */}
      <div className="text-center mb-11">
        <h1 className="fw-bolder mb-3 text-white">Verify Otp</h1>
        {/* <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div> */}
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      ) : null}
      {/* (
        <div className="mb-10 bg-light-info p-8 rounded">
          <div className="text-info">
            Use account <strong>admin@demo.com</strong> and password{" "}
            <strong>demo</strong> to continue.
          </div>
        </div>
      ) */}
      {/* begin::Form group */}
      <div className="fv-row mb-8">
        <label className="form-label fs-6 fw-bolder text-white">
          Enter Otp
        </label>
        <input
          placeholder="Enter otp"
          {...formik.getFieldProps("otp")}
          className={clsx(
            "form-control bg-transparent"
            // { "is-invalid": formik.touched.otp && formik.errors.otp },
            // {
            //   "is-valid": formik.touched.otp && !formik.errors.otp,
            // }
          )}
          type="text"
          name="otp"
          autoComplete="off"
        />
        {formik.touched.otp && formik.errors.otp && (
          <div className="fv-plugins-message-container">
            <span role="alert">{formik.errors.otp}</span>
          </div>
        )}
      </div>
      <div className="d-grid mb-10">
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-primary"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className="indicator-label">Continue</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
    </form>
  );
}
