import { instance, publicInstance } from "./config";

export const getProfileService = () => instance.get("/admin/profile");
export const getUsersService = (query: string = "") =>
  instance.get("/admin/users" + query);
export const getVendorsService = (query: string = "") =>
  instance.get("/admin/vendors" + query);
export const getMoviesService = (query: string = "") =>
  instance.get("/assets/list" + query);
export const getTransactionsService = (query: string = "") =>
  instance.get("/admin/transactions" + query);
export const getUserByIdService = (query: string = "") =>
  instance.get("/admin/users/" + query);
export const getUserTransactionsService = (query: string = "") =>
  instance.get("/admin/users/transactions/" + query);
export const getUserPaymentMethodsService = (query: string = "") =>
  instance.get("/admin/users/payment-methods/" + query);
export const getDashboardService = () => instance.get("/admin/dashboard");
export const getDashboardChartService = () =>
  instance.get("/admin/dashboard/chart");
export const getVendorDashboardService = () =>
  instance.get("/admin/vendorDashboard");
export const getVendorDashboardChartService = () =>
  instance.get("/admin/dashboard/vendorChart");
export const addCharacterService = (obj: any) =>
  instance.put("/vendor/character/add", obj);
export const addMovieService = (obj: any) => instance.put("/asset/create", obj);
export const editMovieService = (obj: any) =>
  instance.patch("/asset/edit", obj);
export const deleteMovieService = (query: any) =>
  instance.delete("/asset/delete/" + query);
export const publishMovieService = (obj: any) =>
  instance.post("/admin/publish/", obj);
export const addGenre = (obj: any) => instance.put("/admin/genre/add", obj);
export const getCharacterService = (query: string) =>
  instance.get("/vendor/character/list" + query || "");
export const getGenreService = (query: string) =>
  instance.get("/admin/genre/list" + query || "");
export const getFolderService = (query: string) =>
  instance.get("/admin/asset/get-folders" + query || "");
export const getSeasonFolderService = (query: string) =>
  instance.get("/admin/season/get-folders" + query || "");
export const addSeasonService = (query: string, obj: any) =>
  instance.put("/vendor/series" + query, obj);
export const addEpisodeService = (query: string, obj: any) =>
  instance.put("/vendor/series" + query, obj);
export const refreshTokenService = (data: any) =>
  publicInstance.post("/refresh", data);
export const editCharacterService = (obj: any) =>
  instance.patch("/vendor/character/edit", obj);
export const editGenreService = (obj: any) =>
  instance.patch("/admin/genre/edit", obj);
export const deleteGenreService = (query: string) =>
  instance.delete("/admin/genre/delete/" + query);
export const editRevisionService = (obj: any) =>
  instance.patch("/asset/version-revision", obj);
export const updateUserStatusService = (data:any) => instance.post('/update-status',data)