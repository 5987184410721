import { useEffect, useState } from "react";
import { PageLink, PageTitle } from "../../../../../../_metronic/layout/core";
import { AddLanguageForm } from "./form/AddLanguageForm";
import { Button, Col, Row, Tab, Tabs } from "react-bootstrap";
import * as yup from "yup";
import { validateFields } from "../../../../../../services/helper";
import {
  addCharacterService,
  addMovieService,
  editMovieService,
  editRevisionService,
} from "../../../../../../services/user";
import { useLocation, useNavigate } from "react-router-dom";
import { AddForm } from "./form/AddForm";
import toast from "react-hot-toast";
import {
  fetchMoviesByIdService,
  getVersionDetailsService,
} from "../../../../../../services/movies";
import Loader from "../../../../../../_metronic/layout/components/Loader";
import axios from "axios";
import moment from "moment";

// import { addCharacterService } from "../../../../services/user";

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: "Series",
    path: "/series",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];

const defaultErrLang = {
  en: false,
  ar: false,
  tr: false,
};
const defaultFile = {
  en: null,
  ar: null,
  tr: null,
};
const defaultLang = {
  en: "",
  ar: "",
  tr: "",
};
const defaultValues = {
  genres: [],
  // characters: [],
  title: defaultLang,
  // languages: [],
  duration: "",
  releaseYear: moment().format("YYYY"),
  rentPrice: "",
  sarRentPrice: "",
  description: defaultLang,
  coverImage: null,
  preview: [],
  starring: [],
  createdBy: [],
  posterImage: null,
  posterPreview: [],
};

const defaultErrorValues = {
  title: defaultErrLang,
  languages: false,
  description: defaultErrLang,
  coverImage: false,
  genres: false,
  characters: false,
  duration: false,
  releaseYear: false,
  rentPrice: false,
  sarRentPrice: false,
  preview: false,
  starring: false,
  createdBy: false,
};
const languageSchema = yup.object().shape({
  en: yup.string().required("Enter value in english"),
  ar: yup.string().required("Enter value in arabic"),
  tr: yup.string().required("Enter value in turkish"),
});

const editSchema = yup.object().shape({
  genres: yup
    .array()
    .of(yup.mixed())
    .min(1, "At least one language is required")
    .required("Languages are required"),
  // characters: yup
  //   .array()
  //   .of(yup.mixed())
  //   .min(1, "At least one language is required")
  //   .required("Languages are required"),
  title: languageSchema,
  releaseYear: yup
    .string()
    .required("Release year is required")
    .matches(/^\d{4}$/, "Release year must be a 4-digit number")
    .test("maxYear", "Release year cannot be in the future", (value) => {
      const currentYear = new Date().getFullYear();
      return parseInt(value, 10) <= currentYear;
    }),
  rentPrice: yup.string().required("Rent price is required"),
  sarRentPrice: yup.string().required("Rent price is required"),
  description: languageSchema,
});
const defaultCharacterValues = [
  {
    name: {
      en: "",
      ar: "",
      tr: "",
    },
    description: {
      en: "",
      ar: "",
      tr: "",
    },
    file: [],
    preview: [],
    deleted: false,
  },
];

const AddSeries = ({ versionId }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const seacrUrl = new URLSearchParams(location.search);
  const seriesId: string = seacrUrl.get("key") || "";
  const [formObj, setformObj] = useState(defaultValues);
  // const [seasonObj,setSeasonObj] = useState(defaultSeasonValues);
  const [charObj, setCharObj] = useState(defaultCharacterValues);
  const [errorObj, setErrorObj] = useState(defaultErrorValues);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [tab, setTab] = useState("en");
  const [step, setStep] = useState(1);

  useEffect(() => {
    setLoading(true);
    getMovieById();
  }, []);
  const handleSelect = (key: any) => {
    setTab(key);
  };

  const getMovieById = async () => {
    try {
      const res = await getVersionDetailsService({ versionId });
      if (res.data.status) {
        const movie = res.data.movie;
        const obj = {
          genres: movie?.genres?.map((item) => ({
            label: item?.title?.en,
            value: item?._id,
          })),
          characters: movie.characters,
          title: {
            en: movie.title.en,
            ar: movie.title.ar,
            tr: movie.title.tr,
          },
          releaseYear: movie.releaseYear,
          rentPrice: movie.rentPrice,
          sarRentPrice: movie.sarRentPrice,
          description: {
            en: movie.description.en,
            ar: movie.description.ar,
            tr: movie.description.tr,
          },
          coverImage: movie.coverImage,
          posterImage: movie.posterImage,
        };
        setformObj((prev) => ({
          ...prev,
          ...obj,
        }));
        const objToChar =
          movie?.characters?.length > 0
            ? movie?.characters?.map((char) => ({
                ...char,
                file: [],
                preview: [],
                deleted: false,
              }))
            : defaultCharacterValues;
        setCharObj(objToChar);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  const handleEdit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      const errors: any = await validateFields(editSchema, formObj);
      if (Object.keys(errors).length > 0) {
        if (
          errors[`title.en`] ||
          errors[`description.en`] ||
          errors[`movie.en`] ||
          errors[`trailer.en`]
        ) {
          setTab("en");
        } else if (
          errors[`title.ar`] ||
          errors[`description.ar`] ||
          errors[`movie.ar`] ||
          errors[`trailer.ar`]
        ) {
          setTab("ar");
        } else if (
          errors[`title.tr`] ||
          errors[`description.tr`] ||
          errors[`movie.tr`] ||
          errors[`trailer.tr`]
        ) {
          setTab("tr");
        }
        return setErrorObj(errors);
      }
      const genres = formObj.genres.map((item: any) => item.value);
      const obj = {
        versionId,
        title: formObj.title,
        description: formObj.description,
        genres,
        releaseYear: formObj.releaseYear,
        rentPrice: formObj.rentPrice,
        sarRentPrice: formObj.sarRentPrice,
        posterImage: formObj.posterImage,
        coverImage: formObj.coverImage,
      };
      const formData = new FormData();
      for (const key in obj) {
        console.log("yyyyyy", key, typeof obj[key]);
        if (key === "title" || key === "description") {
          formData.append(key, JSON.stringify(obj[key]));
        } else if (Array.isArray(obj[key])) {
          obj[key].forEach(function (value) {
            formData.append(key, value);
          });
        } else {
          formData.append(key, obj[key]);
        }
      }
      const result = await editRevisionService(formData);
      if (result.data.status) {
        setformObj(defaultValues);
        toast.success(result.data.message);
        navigate(-1);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>{"Edit Series"}</PageTitle>

      <AddForm
        lang={tab}
        formObj={formObj}
        setformObj={setformObj}
        errorObj={errorObj}
        setErrorObj={setErrorObj}
        defaultErrorValues={defaultErrorValues}
        seriesId={seriesId}
      />

      <Tabs
        activeKey={tab}
        id="uncontrolled-tab-example"
        className="my-5"
        onSelect={handleSelect}
      >
        <Tab
          eventKey="en"
          title={<div style={{ width: "6rem" }}>EN</div>}
        ></Tab>
        <Tab
          eventKey="ar"
          title={<div style={{ width: "6rem" }}>AR</div>}
        ></Tab>
        <Tab
          eventKey="tr"
          title={<div style={{ width: "6rem" }}>TR</div>}
        ></Tab>
      </Tabs>

      <AddLanguageForm
        lang={tab}
        formObj={formObj}
        setformObj={setformObj}
        errorObj={errorObj}
        setErrorObj={setErrorObj}
        defaultErrorValues={defaultErrorValues}
      />

      <Button
        className="mt-5 btn"
        disabled={saving}
        onClick={() => navigate(-1)}
      >
        Cancel
      </Button>
      <Button className="mt-5 ms-5 btn" disabled={saving} onClick={handleEdit}>
        {saving ? "Updating" : "Update"}
      </Button>
    </>
  );
};

export default AddSeries;
