import { useEffect, useState } from "react";
import { PageLink, PageTitle } from "../../../../_metronic/layout/core";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { getVersionDetailsService } from "../../../../services/movies";
import Loader from "../../../../_metronic/layout/components/Loader";
import AddCharacterComponent from "./components/form/AddCharacterComponent";
import moment from "moment";
import { useAuth } from "../../auth";
import AddSeason from "./components/season/AddSeason";
import AddEpisode from "./components/episode/AddEpisode";
import AddMovie from "./components/movie/AddMovie";
import AddSeries from "./components/series/AddSeries";

// import { addCharacterService } from "../../../../services/user";

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: "Movies",
    path: "/movies",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];

const defaultErrLang = {
  en: false,
  ar: false,
  tr: false,
};
const defaultFile = {
  en: null,
  ar: null,
  tr: null,
};
const defaultLang = {
  en: "",
  ar: "",
  tr: "",
};
const defaultValues = {
  genres: [],
  characters: [],
  title: defaultLang,
  // languages: [],
  duration: "",
  releaseYear: moment().format("YYYY"),
  rentPrice: "",
  sarRentPrice: "",
  description: defaultLang,
  coverImage: null,
  preview: [],
  starring: [],
  createdBy: [],
  trailer: defaultFile,
  movie: defaultFile,
  movieProgress: {
    en: 0,
    ar: 0,
    tr: 0,
  },
  trailerProgress: {
    en: 0,
    ar: 0,
    tr: 0,
  },
  moviePreview: defaultFile,
  trailerPreview: defaultFile,
  posterImage: null,
  posterPreview: [],
  trailerUrl: defaultFile,
  videoUrl: defaultFile,
};
const defaultErrorValues = {
  title: defaultErrLang,
  languages: false,
  description: defaultErrLang,
  coverImage: false,
  genres: false,
  characters: false,
  duration: false,
  releaseYear: false,
  rentPrice: false,
  sarRentPrice: false,
  movieUrl: defaultErrLang,
  trailerUrl: defaultErrLang,
  preview: false,
  starring: false,
  createdBy: false,
  trailer: defaultErrLang,
  movie: defaultErrLang,
};

const languageSchema = yup.object().shape({
  en: yup.string().required("Enter value in english"),
  ar: yup.string().required("Enter value in arabic"),
  tr: yup.string().required("Enter value in turkish"),
});
const fileSchema = yup.object().shape({
  en: yup.mixed().required("Enter value in english"),
  ar: yup.mixed().required("Enter value in arabic"),
  tr: yup.mixed().required("Enter value in turkish"),
});

const firstStepSchema = yup.object().shape({
  genres: yup
    .array()
    .of(yup.mixed())
    .min(1, "At least one language is required")
    .required("Languages are required"),
  // characters: yup
  //   .array()
  //   .of(yup.mixed())
  //   .min(1, "At least one language is required")
  //   .required("Languages are required"),
  title: languageSchema,
  // languages: yup
  //   .array()
  //   .of(yup.string())
  //   .min(1, "At least one language is required")
  //   .required("Languages are required"),
  releaseYear: yup
    .string()
    .required("Enter movie release year")
    .matches(/^\d{4}$/, "Release year must be a 4-digit number")
    .test(
      "maxYear",
      "Release year cannot be greater than currentYear",
      (value) => {
        const currentYear = new Date().getFullYear();
        return parseInt(value, 10) <= currentYear;
      }
    ),
  rentPrice: yup.string().required("Rent price is required"),
  sarRentPrice: yup.string().required("Sar rent price is required"),
  description: languageSchema,
  coverImage: yup.mixed().required("Cover image is required"),
});
const secondStepSchema = yup.object().shape({
  genres: yup
    .array()
    .of(yup.mixed())
    .min(1, "At least one language is required")
    .required("Languages are required"),
  // characters: yup
  //   .array()
  //   .of(yup.mixed())
  //   .min(1, "At least one language is required")
  //   .required("Languages are required"),
  title: languageSchema,
  // languages: yup
  //   .array()
  //   .of(yup.string())
  //   .min(1, "At least one language is required")
  //   .required("Languages are required"),
  releaseYear: yup
    .string()
    .required("Release year is required")
    .matches(/^\d{4}$/, "Release year must be a 4-digit number")
    .test("maxYear", "Release year cannot be in the future", (value) => {
      const currentYear = new Date().getFullYear();
      return parseInt(value, 10) <= currentYear;
    }),
  rentPrice: yup.string().required("Rent price is required"),
  sarRentPrice: yup.string().required("Rent price is required"),
  description: languageSchema,
  coverImage: yup.mixed().required("Cover image is required"),
  movie: fileSchema,
  trailer: fileSchema,
});
const editSchema = yup.object().shape({
  genres: yup
    .array()
    .of(yup.mixed())
    .min(1, "At least one language is required")
    .required("Languages are required"),
  // characters: yup
  //   .array()
  //   .of(yup.mixed())
  //   .min(1, "At least one language is required")
  //   .required("Languages are required"),
  title: languageSchema,
  releaseYear: yup
    .string()
    .required("Release year is required")
    .matches(/^\d{4}$/, "Release year must be a 4-digit number")
    .test("maxYear", "Release year cannot be in the future", (value) => {
      const currentYear = new Date().getFullYear();
      return parseInt(value, 10) <= currentYear;
    }),
  rentPrice: yup.string().required("Rent price is required"),
  sarRentPrice: yup.string().required("Rent price is required"),
  description: languageSchema,
});
const defaultCharacterValues = {
  name: {
    en: "",
    ar: "",
    tr: "",
  },
  description: {
    en: "",
    ar: "",
    tr: "",
  },
  file: [],
  preview: [],
  versionId: "",
};
// const defaultCharacterErrorValues = {
//   name: {
//     en: false,
//     ar: false,
//     tr: false,
//   },
//   description: {
//     en: false,
//     ar: false,
//     tr: false,
//   },
//   file: false,
// };
// const defaultCharacterSchema = yup.object().shape({
//   name: languageSchema,
//   description: languageSchema,
//   file: yup
//     .array()
//     .of(yup.mixed())
//     .min(1, "At least one language is required")
//     .required("Languages are required"),
// });

const EditRevision = () => {
  const [modalShow, setModalShow] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const state: any = location.state;
  const [formObj, setformObj] = useState(defaultValues);
  const [charObj, setCharObj] = useState(defaultCharacterValues);
  const [delObj, setDelObj] = useState({
    trailerUrl: defaultFile,
    videoUrl: defaultFile,
  });
  const [errorObj, setErrorObj] = useState(defaultErrorValues);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [count, setCount] = useState(6);
  const [tab, setTab] = useState("en");
  const [step, setStep] = useState(1);
  const [avg, setAvg] = useState(0);
  console.log("releaseyesr", formObj.releaseYear);

  useEffect(() => {
    const avg =
      (+formObj.trailerProgress.en +
        +formObj.trailerProgress.tr +
        +formObj.trailerProgress.ar +
        +formObj.movieProgress.en +
        +formObj.movieProgress.tr +
        +formObj.movieProgress.ar) /
      count;
    setAvg(avg);
  }, [formObj]);

  useEffect(() => {
    if (state.type === "character") {
      fetchVersion();
    }
  }, []);

  const fetchVersion = async () => {
    try {
      setFetching(true);
      let obj = {
        versionId: state.versionId,
      };
      const result = await getVersionDetailsService(obj);
      if (result.data.status) {
        const data = result.data.movie;
        const obj: any = {
          name: data.name || "",
          description: data.description || "",
          versionId: data._id || "",
          file: [],
          preview: [],
          images: data.images,
        };
        console.log("result", data, obj);
        setCharObj(obj);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setFetching(false);
    }
  };

  const handleSelect = (key: any) => {
    setTab(key);
  };
  // console.log("characters",charObj)
  // const handleSubmit = async (e: any) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   try {
  //     const errors: any = await validateFields(secondStepSchema, formObj);
  //     if (Object.keys(errors).length > 0) {
  //       if (
  //         errors[`title.en`] ||
  //         errors[`description.en`] ||
  //         errors[`movie.en`] ||
  //         errors[`trailer.en`]
  //       ) {
  //         setTab("en");
  //       } else if (
  //         errors[`title.ar`] ||
  //         errors[`description.ar`] ||
  //         errors[`movie.ar`] ||
  //         errors[`trailer.ar`]
  //       ) {
  //         setTab("ar");
  //       } else if (
  //         errors[`title.tr`] ||
  //         errors[`description.tr`] ||
  //         errors[`movie.tr`] ||
  //         errors[`trailer.tr`]
  //       ) {
  //         setTab("tr");
  //       }
  //       return setErrorObj(errors);
  //     }
  //     const folder = await getFolderService(
  //       `?title=${formObj.title.en}&type=movie`
  //     );
  //     const [ten, tar, ttr, men, mar, mtr]: [any, any, any, any, any, any] =
  //       await Promise.all([
  //         handleUploadClick(
  //           formObj.trailer.en,
  //           folder.data.urls.en,
  //           formObj.title.en + "_trailer",
  //           formObj.description.en,
  //           setformObj,
  //           "trailerProgress",
  //           "en"
  //         ),
  //         handleUploadClick(
  //           formObj.trailer.ar,
  //           folder.data.urls.ar,
  //           formObj.title.ar + "_trailer",
  //           formObj.description.ar,
  //           setformObj,
  //           "trailerProgress",
  //           "ar"
  //         ),
  //         handleUploadClick(
  //           formObj.trailer.tr,
  //           folder.data.urls.tr,
  //           formObj.title.tr + "_trailer",
  //           formObj.description.tr,
  //           setformObj,
  //           "trailerProgress",
  //           "tr"
  //         ),
  //         handleUploadClick(
  //           formObj.movie.en,
  //           folder.data.urls.en,
  //           formObj.title.en,
  //           formObj.description.en,
  //           setformObj,
  //           "movieProgress",
  //           "en"
  //         ),
  //         handleUploadClick(
  //           formObj.movie.ar,
  //           folder.data.urls.ar,
  //           formObj.title.ar,
  //           formObj.description.ar,
  //           setformObj,
  //           "movieProgress",
  //           "ar"
  //         ),
  //         handleUploadClick(
  //           formObj.movie.tr,
  //           folder.data.urls.tr,
  //           formObj.title.tr,
  //           formObj.description.tr,
  //           setformObj,
  //           "movieProgress",
  //           "tr"
  //         ),
  //       ]);

  //     const genres = formObj.genres.map((item: any) => item.value);
  //     const characters = await Promise.all(
  //       charObj?.map(async (char) => {
  //         // console.log("char",char)
  //         const formCharData = new FormData();
  //         for (const key in char) {
  //           if (char.hasOwnProperty(key)) {
  //             if (key !== "preview" && key !== "file") {
  //               formCharData.append(key, JSON.stringify(char[key]));
  //             } else if (key === "file") {
  //               if (char.file && char.file?.length > 0) {
  //                 char.file.forEach((file: Blob, index: number) => {
  //                   formCharData.append(`images`, file);
  //                 });
  //               }
  //             }
  //           }
  //         }
  //         formCharData.append(`type`, "movie");
  //         const charResult = await addCharacterService(formCharData);
  //         if (!charResult.data.status) {
  //           return toast.error(charResult.data.message);
  //         }
  //         // console.log(charResult)
  //         return charResult.data.id;
  //       })
  //     );

  //     // console.log("result",{characters})
  //     const obj = {
  //       title: formObj.title,
  //       description: formObj.description,
  //       type: "movie",
  //       genres,
  //       characters,
  //       // languages: formObj.languages,
  //       releaseYear: formObj.releaseYear,
  //       rentPrice: formObj.rentPrice,
  //       sarRentPrice: formObj.sarRentPrice,
  //       duration: men?.duration,
  //       coverImage: formObj.coverImage ? formObj.coverImage[0] : [],
  //       posterImage: formObj.posterImage ? formObj.posterImage[0] : [],
  //       videoUrl: {
  //         en: extractMovieCode(men.uploadData.uri),
  //         ar: extractMovieCode(mar.uploadData.uri),
  //         tr: extractMovieCode(mtr.uploadData.uri),
  //       },
  //       trailerUrl: {
  //         en: extractMovieCode(ten.uploadData.uri),
  //         ar: extractMovieCode(tar.uploadData.uri),
  //         tr: extractMovieCode(ttr.uploadData.uri),
  //       },
  //       folderId: folder.data.mainFolder,
  //     };
  //     const formData = new FormData();
  //     for (const key in obj) {
  //       if (obj.hasOwnProperty(key)) {
  //         if (
  //           key === "videoUrl" ||
  //           key === "title" ||
  //           key === "description" ||
  //           key === "trailerUrl"
  //         ) {
  //           formData.append(key, JSON.stringify(obj[key]));
  //         } else if (Array.isArray(obj[key])) {
  //           obj[key].forEach(function (value) {
  //             formData.append(key, value);
  //           });
  //         } else {
  //           formData.append(key, obj[key]);
  //         }
  //       }
  //     }

  //     const result = await addMovieService(formData);
  //     if (result.data.status) {
  //       setformObj(defaultValues);
  //       toast.success(result.data.message);
  //       // navigate("/movies");
  //       navigate("/requests");
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  // console.log("tpro", state, formObj.trailerProgress);
  // const handleEdit = async (e: any) => {
  //   e.preventDefault();
  //   setFetching(true);
  //   try {
  //     const errors: any = await validateFields(editSchema, formObj);
  //     if (Object.keys(errors).length > 0) {
  //       if (
  //         errors[`title.en`] ||
  //         errors[`description.en`] ||
  //         errors[`movie.en`] ||
  //         errors[`trailer.en`]
  //       ) {
  //         setTab("en");
  //       } else if (
  //         errors[`title.ar`] ||
  //         errors[`description.ar`] ||
  //         errors[`movie.ar`] ||
  //         errors[`trailer.ar`]
  //       ) {
  //         setTab("ar");
  //       } else if (
  //         errors[`title.tr`] ||
  //         errors[`description.tr`] ||
  //         errors[`movie.tr`] ||
  //         errors[`trailer.tr`]
  //       ) {
  //         setTab("tr");
  //       }
  //       return setErrorObj(errors);
  //     }
  //     const genres = formObj.genres.map((item: any) => item.value);
  //     const obj = {
  //       id: "movieId",
  //       title: formObj.title,
  //       description: formObj.description,
  //       type: "movie",
  //       genres,
  //       releaseYear: formObj.releaseYear,
  //       rentPrice: formObj.rentPrice,
  //       sarRentPrice: formObj.sarRentPrice,
  //     };
  //     const result = await editMovieService(obj);
  //     if (result.data.status) {
  //       setformObj(defaultValues);
  //       toast.success(result.data.message);
  //       navigate("/movies");
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   } finally {
  //     setFetching(false);
  //   }
  // };
  // const handleEditVideos = async (e: any) => {
  //   e.preventDefault();
  //   console.log("delobj", delObj);
  //   setLoading(true);
  //   try {
  //     let cnt = 0;
  //     let errors: any = {};
  //     if (!formObj.trailerUrl.en && !formObj.trailer.en) {
  //       errors["trailer.en"] = "Select trailer in english";
  //     } else if (!formObj.trailerUrl.ar && !formObj.trailer.ar) {
  //       errors["trailer.ar"] = "Select trailer in arabic";
  //     } else if (!formObj.trailerUrl.tr && !formObj.trailer.tr) {
  //       errors["trailer.tr"] = "Select trailer in turkis";
  //     } else if (!formObj.videoUrl.tr && !formObj.movie.tr) {
  //       errors["movie.tr"] = "Select movie in turkis";
  //     } else if (!formObj.videoUrl.ar && !formObj.movie.ar) {
  //       errors["movie.ar"] = "Select movie in arabic";
  //     } else if (!formObj.videoUrl.en && !formObj.movie.en) {
  //       errors["movie.en"] = "Select movie in english";
  //     }
  //     if (Object.keys(errors).length > 0) {
  //       if (errors[`movie.en`] || errors[`trailer.en`]) {
  //         setTab("en");
  //       } else if (errors[`movie.ar`] || errors[`trailer.ar`]) {
  //         setTab("ar");
  //       } else if (errors[`movie.tr`] || errors[`trailer.tr`]) {
  //         setTab("tr");
  //       }
  //       return setErrorObj(errors);
  //     }
  //     if (!formObj.videoUrl.tr) {
  //       cnt++;
  //     } else if (!formObj.videoUrl.ar) {
  //       cnt++;
  //     } else if (!formObj.videoUrl.en) {
  //       cnt++;
  //     } else if (!formObj.trailerUrl.ar) {
  //       cnt++;
  //     } else if (!formObj.trailerUrl.tr) {
  //       cnt++;
  //     } else if (!formObj.trailerUrl.en) {
  //       cnt++;
  //     }
  //     if (cnt > 0) {
  //       setCount(cnt);
  //     }
  //     const [ten, tar, ttr, men, mar, mtr]: [any, any, any, any, any, any] =
  //       await Promise.allSettled([
  //         !formObj.trailerUrl.en &&
  //           handleReplaceClick(
  //             formObj.trailer.en,
  //             formObj.title.en + "_trailer",
  //             formObj.description.en,
  //             setformObj,
  //             "trailerProgress",
  //             "en",
  //             delObj.trailerUrl.en
  //           ),
  //         !formObj.trailerUrl.ar &&
  //           handleReplaceClick(
  //             formObj.trailer.ar,
  //             formObj.title.ar + "_trailer",
  //             formObj.description.ar,
  //             setformObj,
  //             "trailerProgress",
  //             "ar",
  //             delObj.trailerUrl.ar
  //           ),
  //         !formObj.trailerUrl.tr &&
  //           handleReplaceClick(
  //             formObj.trailer.tr,
  //             formObj.title.tr + "_trailer",
  //             formObj.description.tr,
  //             setformObj,
  //             "trailerProgress",
  //             "tr",
  //             delObj.trailerUrl.tr
  //           ),
  //         !formObj.videoUrl.en &&
  //           handleReplaceClick(
  //             formObj.movie.en,
  //             formObj.title.en,
  //             formObj.description.en,
  //             setformObj,
  //             "movieProgress",
  //             "en",
  //             delObj.videoUrl.en
  //           ),
  //         !formObj.videoUrl.ar &&
  //           handleReplaceClick(
  //             formObj.movie.ar,
  //             formObj.title.ar,
  //             formObj.description.ar,
  //             setformObj,
  //             "movieProgress",
  //             "ar",
  //             delObj.videoUrl.ar
  //           ),
  //         !formObj.videoUrl.tr &&
  //           handleReplaceClick(
  //             formObj.movie.tr,
  //             formObj.title.tr,
  //             formObj.description.tr,
  //             setformObj,
  //             "movieProgress",
  //             "tr",
  //             delObj.videoUrl.tr
  //           ),
  //       ]);
  //     // const obj = {
  //     //   videoUrl: {
  //     //     en: extractMovieCode(men.value.uploadData.uri),
  //     //     ar: extractMovieCode(mar.value.uploadData.uri),
  //     //     tr: extractMovieCode(mtr.value.uploadData.uri),
  //     //   },
  //     //   trailerUrl: {
  //     //     en: extractMovieCode(ten.value.uploadData.uri),
  //     //     ar: extractMovieCode(tar.value.uploadData.uri),
  //     //     tr: extractMovieCode(ttr.value.uploadData.uri),
  //     //   },
  //     //   folderId: folder.data.mainFolder,
  //     // };
  //     // const result = await editMovieService(obj);
  //     // if (result.data.status) {
  //     //   setformObj(defaultValues);
  //     //   toast.success(result.data.message);
  //     //   navigate("/movies");
  //     // }
  //     navigate(-1);
  //   } catch (err) {
  //     console.error(err);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  // const handleNextStep = async () => {
  //   const errors: any = await validateFields(firstStepSchema, formObj);
  //   if (Object.keys(errors).length > 0) {
  //     if (errors[`title.en`] || errors[`description.en`]) {
  //       setTab("en");
  //     } else if (errors[`title.ar`] || errors[`description.ar`]) {
  //       setTab("ar");
  //     } else if (errors[`title.tr`] || errors[`description.tr`]) {
  //       setTab("tr");
  //     }
  //     return setErrorObj(errors);
  //   }
  //   setTab("en");
  //   setStep(2);
  // };
  console.log("state", state);
  return fetching ? (
    <Loader />
  ) : (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>
        {"movieId" ? "Edit Movie" : "Add Movie"}
      </PageTitle>
      {state?.type === "movie" ? (
        <AddMovie versionId={state?.versionId} />
      ) : state?.type === "character" ? (
        <AddCharacterComponent
          setStep={setStep}
          setCharObj={setCharObj}
          onSubmit={null}
          movieId={"movieId"}
          charObj={charObj}
        />
      ) : state?.type === "season" ? (
        <AddSeason versionId={state?.versionId} />
      ) :state?.type==="series"? (
        <AddSeries versionId={state?.versionId} />
      ):(
        <AddEpisode versionId={state?.versionId} />
      )}
    </>
  );
};

export default EditRevision;
