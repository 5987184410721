/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { KTIcon } from "../../../../../_metronic/helpers";
import { Card4 } from "../../../../../_metronic/partials/content/cards/Card4";
import { TablesWidget13 } from "../../../../../_metronic/partials/widgets";
import { getUserTransactionsService } from "../../../../../services/user";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import { getMovieTransactionsService } from "../../../../../services/movies";
import { Pagination } from "react-bootstrap";
import Loader from "../../../../../_metronic/layout/components/Loader";

export function Transactions() {
  const params = useParams();
  const [page, setPage] = useState(1);
  const [loading,setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const headers = [
    "Date",
    "Status",
    "User",
    "Payment ID",
    "Merchant Ref.",
    "Subtotal",
    "Total",
    "Receipt",
  ];
  const [transactions, setTransactions] = useState<any[]>([]);
  useEffect(() => {
    page===1&&setLoading(true);
    getTransactions();
  }, [page]);

  const getTransactions = async () => {
    try {
      const res = await getMovieTransactionsService(`${params.id}?page=${page}`);
      if (res.data.status) {
        const total = Math.ceil(res.data.total / 10);
        setTransactions(res.data.transactions);
        setTotalPage(total);
      }
    } catch (e) {
      console.log(e);
    }finally{
      setLoading(false);
    }
  };
  
  return (
    loading?<Loader />:
    <>
      <div className={`card`}>
        {/* begin::Header */}

        {/* end::Header */}
        {/* begin::Body */}
        <div className="card-body py-3">
          {/* begin::Table container */}
          <div className="table-responsive">
            {/* begin::Table */}
            <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
              {/* begin::Table head */}
              <thead>
                <tr className="fw-bold text-muted">
                  {headers?.map((title) => (
                    <th className="min-w-150px">{title}</th>
                  ))}
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {transactions.map((row: any) => {
                  return (
                    <tr>
                      <td>
                        <p className="text-dark fw-bold text-hover-primary">
                          {moment(row.created).format("MMM DD YYYY - hh:mm A")}
                        </p>
                      </td>
                      <td>
                        <p
                          className={`text-dark fw-bold text-hover-primary text-capitalize ${
                            row.status === "success"
                              ? "badge-success"
                              : "badge-danger"
                          } py-1 text-center w-50 rounded`}
                        >
                          {row.status}
                        </p>
                      </td>

                      <td>
                        <Link
                          to={`/users/${row.userId?.id}`}
                          className="text-dark fw-bold text-hover-primary d-block mb-1"
                        >
                          {row.userId?.name ||
                            row?.userId?.email ||
                            row?.userId?.idEmail}
                        </Link>
                      </td>
                      <td>
                        <p className="text-dark fw-bold text-hover-primary d-block mb-1">
                          {row.paymentId}
                        </p>
                      </td>
                      <td>
                        <p className="text-dark fw-bold text-hover-primary d-block mb-1">
                          {row.merchantRef}
                        </p>
                      </td>
                      <td>
                        <p className="text-dark fw-bold text-hover-primary d-block mb-1">
                          SAR {row.subtotal}
                        </p>
                      </td>
                      <td className="text-dark fw-bold text-hover-primary">
                        SAR {row.total}
                      </td>
                      <td className="text-dark fw-bold text-hover-primary">
                        {row.status === "success" && <a
                          href={
                            row.receiptUrl
                              ? process.env.REACT_APP_CLOUDFRONT_URL +
                                row.receiptUrl
                              : "#"
                          }
                          className="badge badge-light fw-bolder fs-6"
                          style={{ color: "#FB8624", cursor: "pointer" }}
                        >
                          Download
                        </a>}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      {totalPage > 0 && (
        <div className="mt-2">
          <Pagination className="d-flex justify-content-end">
            {Array.from({
              length: totalPage,
            }).map((_, index) => {
              let i = index + 1;
              return (
                <Pagination.Item
                  key={i}
                  active={i === page}
                  onClick={() => setPage(i)}
                >
                  {i}
                </Pagination.Item>
              );
            })}
          </Pagination>
        </div>
      )}
    </>
  );
}
