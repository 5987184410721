import { Route, Routes } from "react-router-dom";
import { Login } from "./components/Login";
import { AuthLayout } from "./AuthLayout";
import { Otp } from "./components/Otp";
import { SignUpPage } from "./SignUpPage";
import { SignUp } from "./components/SignUp";

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route index element={<Login />} />
      <Route path="/verify-otp"  element={<Otp />} />
      <Route path="/vendor-signup" element={<SignUp />} />
    </Route>
  </Routes>
);

export { AuthPage };
