import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getDashboardChartService,
  getDashboardService,
  getUsersService,
  getVendorDashboardChartService,
  getVendorDashboardService,
} from "../../../services/user";
// import { getProfileService } from "../../services/user";
// import { getPaymentMethodsService } from "../../services/payment-methods";

export const getDashboardThunk = createAsyncThunk(
  "user/getDashboard",
  async () => {
    try {
      const res = await getDashboardService();
      if (res.data.status) {
        return res.data.data;
      }
    } catch (e) {
      console.log(e);
      return {};
    }
  }
);
export const getVendorDashboardThunk = createAsyncThunk(
  "user/getVendorDashboard",
  async () => {
    try {
      const res = await getVendorDashboardService();
      if (res.data.status) {
        return res.data.data;
      }
    } catch (e) {
      console.log(e);
      return {};
    }
  }
);
export const getDashboardChartThunk = createAsyncThunk(
  "user/getDashboardChart",
  async () => {
    try {
      const res = await getDashboardChartService();
      if (res.data.status) {
        return res.data.data;
      }
    } catch (e) {
      console.log(e);
      return {};
    }
  }
);
export const getVendorDashboardChartThunk = createAsyncThunk(
  "user/getVendorDashboardChart",
  async () => {
    try {
      const res = await getVendorDashboardChartService();
      if (res.data.status) {
        return res.data.data;
      }
    } catch (e) {
      console.log(e);
      return {};
    }
  }
);

// export const getUserPaymentMethodsThunk = createAsyncThunk(
//   "user/getPaymentMethods",
//   async () => {
//     try {
//       const res = await getPaymentMethodsService();
//       if (res.data.status) {
//         return res.data.list;
//       }
//     } catch (e) {
//       console.log(e);
//       return [];
//     }
//   }
// );
