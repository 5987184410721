/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import noUiSlider, { target } from "nouislider";
import { useLayout } from "../../core";
import { KTIcon } from "../../../helpers";
import { DefaultTitle } from "./page-title/DefaultTitle";
import { ThemeModeSwitcher } from "../../../partials";
import { useAuth } from "../../../../app/modules/auth";

const HeaderToolbar = () => {
  const { classes } = useLayout();
  const [status, setStatus] = useState<string>("1");
  const { logout } = useAuth();

  useEffect(() => {
    const slider: target = document.querySelector(
      "#kt_toolbar_slider"
    ) as target;
    const rangeSliderValueElement: Element | null = document.querySelector(
      "#kt_toolbar_slider_value"
    );

    if (!slider) {
      return;
    }

    slider.innerHTML = "";

    noUiSlider.create(slider, {
      start: [5],
      connect: [true, false],
      step: 1,
      range: {
        min: [1],
        max: [10],
      },
    });

    slider.noUiSlider?.on("update", function (values: any, handle: any) {
      if (!rangeSliderValueElement) {
        return;
      }

      rangeSliderValueElement.innerHTML = parseInt(values[handle]).toFixed(1);
    });
  }, []);

  return (
    <div className="toolbar d-flex align-items-stretch">
      {/* begin::Toolbar container */}
      <div
        className={`${classes.headerContainer.join(
          " "
        )} py-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-stretch justify-content-lg-between`}
      >
        <DefaultTitle />
        <div className="d-flex align-items-stretch overflow-auto pt-3 pt-lg-0">
          {/* begin::Action wrapper */}
          <div className="d-flex align-items-center">
            {/* begin::Label */}

            {/* end::Label */}

            {/* begin::Actions */}
            <div className="d-flex">
              {/* begin::Action */}
              {/* <a
                href="#"
                className="btn btn-sm btn-icon btn-icon-muted btn-active-icon-primary"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_invite_friends"
              >
                <KTIcon iconName="files" className="fs-1" />
              </a> */}

              {/* end::Action */}

              {/* begin::Theme mode */}
              {/* <div className="d-flex align-items-center">
                <ThemeModeSwitcher toggleBtnClass="btn btn-sm btn-icon btn-icon-muted btn-active-icon-primary" />
              </div> */}
              <div className="menu-item px-5">
                <a onClick={logout} className="menu-link px-5">
                  Sign Out
                </a>
              </div>
              {/* end::Theme mode */}
            </div>
            {/* end::Actions */}
          </div>
          {/* end::Action wrapper */}
        </div>
        {/* end::Toolbar container */}
      </div>
    </div>
  );
};

export { HeaderToolbar };
