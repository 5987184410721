export * as KTUtil from "./_utils/index";
export * as components from "./components/index";
// declare global {
//   interface Window {
//     keenthemes: any;
//   }
// }
export function formatMinutes(totalMinutes: number) {
  const minutes = totalMinutes % 60;
  const hours = Math.floor(totalMinutes / 60);

  return { hours, minutes };
}
export var monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getMonths = (fromDate: Date, toDate: Date) => {
  const fromYear = fromDate.getFullYear();
  const fromMonth = fromDate.getMonth();
  const toYear = toDate.getFullYear();
  const toMonth = toDate.getMonth();
  const months: any[] = [];

  for (let year = fromYear; year <= toYear; year++) {
    let monthNum = year === fromYear ? fromMonth : 0;
    const monthLimit = year === toYear ? toMonth : 11;

    for (; monthNum <= monthLimit; monthNum++) {
      let month = monthNum;
      months.push({ year, month });
    }
  }
  return months;
};
export function secondsToTime(secs: number) {
  var hours = Math.floor(secs / (60 * 60));

  var divisor_for_minutes = secs % (60 * 60);
  var minutes = Math.floor(divisor_for_minutes / 60);

  var divisor_for_seconds = divisor_for_minutes % 60;
  var seconds = Math.ceil(divisor_for_seconds);

  var obj = {
    h: hours,
    m: minutes,
    s: seconds,
  };
  return obj;
}
// window.keenthemes = {
//   components: {
//     ScrollTop: components.ScrollTopComponent,
//     Coockie: components.CookieComponent,
//     Drawer: components.DrawerComponent,
//     Feedback: components.FeedbackComponent,
//     ImageInput: components.ImageInputComponent,
//     Scroll: components.ScrollComponent,
//     Stepper: components.StepperComponent,
//     Sticky: components.StickyComponent,
//     Toggle: components.ToggleComponent,
//     Menu: components.MenuComponent,
//     Search: components.SearchComponent,
//     Dialder: components.DialerComponent,
//     PasswordMeter: components.PasswordMeterComponent,
//     Place: components.PlaceComponent,
//     defaultDialerOptions: components.defaultDialerOptions,
//     defaultDialderQueries: components.defaultDialerQueires,
//     defaultPasswordMeterOptions: components.defaultPasswordMeterOptions,
//     defaultPasswordMeterQueries: components.defaultPasswordMeterQueires,
//     defaultPlaceOptions: components.defaultPlaceOptions,
//     defaultPlaceQueries: components.defaultPlaceQueires,
//     defaultDrawerOptions: components.defaultDrawerOptions,
//     defaultFeedbackOptions: components.defaultFeedbackOptions,
//     defaultImageInputOptions: components.defaultImageInputOptions,
//     defaultScrollOptions: components.defaultScrollOptions,
//     defaultScrollTopOptions: components.defaultScrollTopOptions,
//     defaultStepperOptions: components.defaultStepperOptions,
//     defaultStickyOptions: components.defaultStickyOptions,
//     defaultToggleOptions: components.defaultToggleOptions,
//     defaultMenuOptions: components.defaultMenuOptions,
//     defaultSearchOptions: components.defaultSearchOptions,
//   },
//   utils: KTUtil,
// };
