/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { KTIcon, toAbsoluteUrl } from "../../../../_metronic/helpers";
import { Link, useLocation, useParams } from "react-router-dom";
import { Dropdown1 } from "../../../../_metronic/partials";
import { formatMinutes } from "../../../../_metronic/assets/ts";
import { IMAGE_URL, secondsToTime } from "../../../../services/helper";
import { UsersListLoading } from "../user-management/users-list/components/loading/UsersListLoading";
import Loader from "../../../../_metronic/layout/components/Loader";

const ProfileHeader = ({ movie, totalTransactions }: any) => {
  const location = useLocation();
  const params = useParams();
  return !movie ? (
    <Loader />
  ) : (
    <div className="card mb-5 mb-xl-10">
      <div className="card-body pt-9 pb-0">
        <div className="d-flex flex-wrap flex-sm-nowrap mb-3 gap-3">
          <div className="me-7 mb-4">
            <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
              <img src={IMAGE_URL + movie?.coverImage} alt="" />
              {/* <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px"></div> */}
            </div>
          </div>

          <div className="flex-grow-1">
            <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center mb-2">
                  <a
                    href="#"
                    className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1"
                  >
                    {movie?.title?.en}
                  </a>
                  {/* <a href="#">
                    <KTIcon iconName="verify" className="fs-1 text-primary" />
                  </a> */}
                </div>

                <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                  {/* <a
                    href="#"
                    className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                  >
                    <KTIcon iconName="profile-circle" className="fs-4 me-1" />
                    Developer
                  </a> */}
                  <a
                    href="#"
                    className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                  >
                    <KTIcon iconName="subtitle" className="fs-4 me-1" />
                    {movie?.languages?.join(" | ")}
                  </a>
                  <a
                    href="#"
                    className="d-flex align-items-center text-gray-400 text-hover-primary mb-2"
                  >
                    <KTIcon iconName="time" className="fs-4 me-1" />
                    {secondsToTime(movie?.duration).h} hr{" "}
                    {secondsToTime(movie?.duration).m} min
                  </a>
                </div>
              </div>
            </div>

            <div className="d-flex flex-wrap flex-stack">
              <div className="d-flex flex-column flex-grow-1 pe-8">
                <div className="d-flex flex-wrap">
                  <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                    <div className="d-flex align-items-center">
                      <KTIcon
                        iconName="dollar"
                        className="fs-3 text-success me-2"
                      />
                      <div className="fs-2 fw-bolder">{totalTransactions}</div>
                    </div>

                    <div className="fw-bold fs-6 text-gray-400">
                      Times rented
                    </div>
                  </div>
                  {/* <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                    <div className="d-flex align-items-center">
                      <KTIcon
                        iconName="arrow-up"
                        className="fs-3 text-success me-2"
                      />
                      <div className="fs-2 fw-bolder">60%</div>
                    </div>

                    <div className="fw-bold fs-6 text-gray-400">
                      Success Rate
                    </div>
                  </div> */}
                </div>
              </div>

              {/* <div className="d-flex align-items-center w-200px w-sm-300px flex-column mt-3">
                <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                  <span className="fw-bold fs-6 text-gray-400">
                    Profile Compleation
                  </span>
                  <span className="fw-bolder fs-6">50%</span>
                </div>
                <div className="h-5px mx-3 w-100 bg-light mb-3">
                  <div
                    className="bg-success rounded h-5px"
                    role="progressbar"
                    style={{ width: "50%" }}
                  ></div>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div className="d-flex overflow-auto h-55px">
          <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
            {/* <li className="nav-item">
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === "/movies/" + params.id + "/overview" &&
                    "active")
                }
                to={"/movies/" + params.id + "/overview"}
              >
                Overview
              </Link>
            </li> */}
            <li className="nav-item">
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === "/movies/" + params.id + "/overview" &&
                    "active")
                }
                to={"/movies/" + params.id + "/overview"}
              >
                Overview
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname ===
                    "/movies/" + params.id + "/transaction" && "active")
                }
                to={"/movies/" + params.id + "/transaction"}
              >
                Rent Transactions
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export { ProfileHeader };
