import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getCharacterService,
  getGenreService,
  getMoviesService,
  getTransactionsService,
  getUsersService,
  getVendorsService,
} from "../../../services/user";
import axios from "axios";
import { getApprovalRequestsService } from "../../../services/movies";
import { getPayoutsService } from "../../../services/payouts";
import { getVendorPayoutsService } from "../../../services/vendor/payouts";

export const getUserListThunk = createAsyncThunk(
  "user/getUserList",
  async (query: string) => {
    try {
      const res = await getUsersService(query);
      if (res.data.status) {
        const user = {
          userList: res.data.users,
          total: res.data.total,
        };
        return user;
      }
    } catch (e) {
      console.log(e);
      return {};
    }
  }
);
export const getVendorsListThunk = createAsyncThunk(
  "user/getVendorsList",
  async (query: string) => {
    try {
      const res = await getVendorsService(query);
      if (res.data.status) {
        const user = {
          userList: res.data.users,
          total: res.data.total,
        };
        return user;
      }
    } catch (e) {
      console.log(e);
      return {};
    }
  }
);
export const getRequestesListThunk = createAsyncThunk(
  "user/getRequestsList",
  async (query: string) => {
    try {
      const res = await getApprovalRequestsService(query);
      if (res.data.status) {
        const user = {
          list: res.data.movies,
          total: res.data.total,
        };
        return user;
      }
    } catch (e) {
      console.log(e);
      return {};
    }
  }
);
export const getMovieListThunk = createAsyncThunk(
  "user/getMovieList",
  async (query: string) => {
    try {
      const res = await getMoviesService(query);
      if (res.data.status) {
        const asset = {
          movieList: res.data.assets,
          total: res.data.total,
        };
        return asset;
      }
    } catch (e) {
      console.log(e);
      return {};
    }
  }
);
export const getSeriesListThunk = createAsyncThunk(
  "user/getSeriesList",
  async (query: string) => {
    try {
      const res = await getMoviesService(query);
      if (res.data.status) {
        const asset = {
          seriesList: res.data.assets,
          total: res.data.total,
        };
        return asset;
      }
    } catch (e) {
      console.log(e);
      return {};
    }
  }
);
export const getTransactionListThunk = createAsyncThunk(
  "user/getTransactionList",
  async (query: string) => {
    try {
      const res = await getTransactionsService(query);
      if (res.data.status) {
        const asset = {
          transactionList: res.data.transactions,
          total: res.data.total,
        };
        return asset;
      }
    } catch (e) {
      console.log(e);
      return {};
    }
  }
);
export const getPayoutsThunk = createAsyncThunk(
  "user/getPayoutsList",
  async ({
    query,
    role,
  }: {
    query: string;
    role: string;
  }) => {
    try {
      const res = await (role === 'vendor' ? getVendorPayoutsService : getPayoutsService)(query);
      if (res.data.status) {
        const asset = {
          list: res.data.payouts,
          total: res.data.total,
        };
        return asset;
      }
    } catch (e) {
      console.log(e);
      return {};
    }
  }
);
export const getGenreThunk = createAsyncThunk(
  "user/getGenreList",
  async (query: string) => {
    try {
      const res = await getGenreService(query);
      if (res.data.status) {
        return res.data.genres;
      }
    } catch (e) {
      console.log(e);
      return {};
    }
  }
);
export const getCharacterThunk = createAsyncThunk(
  "user/getCharacterList",
  async (query: string) => {
    try {
      const res = await getCharacterService(query);
      if (res.data.status) {
        return res.data.characters;
      }
    } catch (e) {
      console.log(e);
      return {};
    }
  }
);
export const getExchangeThunk = createAsyncThunk(
  "user/getExchangeRate",
  async () => {
    try {
      try {
        const url = `https://v6.exchangerate-api.com/v6/${process.env.REACT_APP_CURRENCY_KEY}/latest/USD`;
        const response = await axios.get(url);
        const rate = response.data.conversion_rates.SAR;
        return rate;
      } catch (error) {
        console.error("Error fetching exchange rate:", error);
        return 1;
      }
    } catch (e) {
      console.log(e);
      return {};
    }
  }
);
export const addEpisodesThunk = createAsyncThunk(
  "user/addEpisodesList",
  (query: [any]) => {
    return query;
  }
);
