import { instance, publicInstance } from "./config";

export const getMoviesByIdService = (query: string = "") =>
  instance.get("/asset/" + query);
export const fetchMoviesByIdService = (query: string = "") =>
  instance.get("/admin/asset/" + query);
export const getSeasonByIdService = (query: string = "") =>
  instance.get("/season/" + query);
export const getMovieTransactionsService = (query: string = "") =>
  instance.get("/movies/transactions/" + query);
export const getSeasonsService = (query: string = "") =>
  instance.get("vendor/series/" + query);
export const getEpisodeByIdService = (query: string = "") =>
  instance.get("/series/seasons/episode/" + query);
export const deleteSeasonService = (query: any) =>
  instance.delete("/vendor/series/season/delete/" + query);
export const deleteEpisodeService = (query: any) =>
  instance.delete("/vendor/series/season/episode/delete/" + query);
export const getApprovalRequestsService = (query: any) =>
  instance.get("/admin/getVersionRequest" + query);
export const getVersionDetailsService = (data: any) =>
  instance.post("/admin/getVersionDetail", data);
export const versionApproveService = (data: any) =>
  instance.put("/admin/versionApproved", data);
