import React, { useRef } from "react";
import {
  Row,
  Col,
  FormGroup,
  FormLabel,
  FormControl
} from "react-bootstrap";

export const AddLanguageForm = ({
  lang,
  formObj,
  setformObj,
  errorObj,
  setErrorObj,
  defaultErrorValues,
}) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorObj(defaultErrorValues);
    const { name, value } = event.target;

    setformObj((prev: any) => ({
      ...prev,
      [name]: {
        ...prev[name],
        [lang]: value,
      },
    }));
  };
  return (
    <Row>
      <Col lg={9}>
        <Row>
          <Col>
            <FormGroup className="form-group">
              <FormLabel className="mb-1">Title</FormLabel>
              <FormControl
                className="form-control"
                name="title"
                type="text"
                value={formObj.title[lang]}
                placeholder="Enter title"
                onChange={handleInputChange}
              />
              {errorObj[`title.${lang}`] && (
                <div className="error">Enter title</div>
              )}
            </FormGroup>
          </Col>
          <FormGroup className="form-group mt-5">
            <FormLabel className="mb-1">Description</FormLabel>
            <FormControl
              className="form-control"
              value={formObj.description[lang]}
              name="description"
              as="textarea"
              rows={6}
              placeholder="Enter description"
              onChange={handleInputChange}
            />
            {errorObj[`description.${lang}`] && (
              <div className="error">Enter movie description</div>
            )}
          </FormGroup>
        </Row>
      </Col>
    </Row>
  );
};
