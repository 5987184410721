import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  FormGroup,
  FormLabel,
  FormControl,
  Image,
  Button,
} from "react-bootstrap";
import { useAppSelector } from "../../../../../../redux/hooks";

export const AddForm = ({
  lang,
  formObj,
  setformObj,
  errorObj,
  setErrorObj,
  defaultErrorValues,
  seasonId,
  setSeasonId,
  setParenFolderId,
  seasonList,
}) => {
  const [characterOptions, setCharacterOptions] = useState([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { characterList } = useAppSelector((state) => state.management);

  useEffect(() => {
    const result = characterList?.map((item) => ({
      label: item?.name?.en,
      value: item?._id,
    }));
    setCharacterOptions(result);
  }, [characterList]);
  const handleSingleImageDelete = async (index: any) => {
    setErrorObj(defaultErrorValues);
    // const updated = formObj.preview.filter((item, i) => i !== index);
    // const updatedFile = [...formObj.coverImage];
    // updatedFile.splice(index, 1);
    setformObj((prev) => ({
      ...prev,
      coverImage: null,
      preview: [],
    }));
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log(1223)
    setErrorObj(defaultErrorValues);
    const { name, value } = event.target;
    if (name === "coverImage") {
      const selectedFile = Array.from(event.target.files || []);
      if (selectedFile) {
        setformObj((prev: any) => ({
          ...prev,
          coverImage: selectedFile,
        }));
        previewImages(selectedFile);
      }
    } else if (name === "releaseYear" || name === "rentPrice") {
      const filteredValue = value.replace(/[^0-9]/g, "");
      setformObj((prev) => ({
        ...prev,
        [name]: filteredValue,
      }));
    } else if (name === "season") {
      setSeasonId(value);
      // const pId = seasonList.reduce((acc,crr)=>{
      //   if(crr._id===value){
      //     acc = crr.folderId
      //   };
      //   return acc;
      // },"")
      const pId = seasonList?.filter((acc, idx) => acc._id === value);

      setParenFolderId(pId![0]?.folderId);
    } else {
      setformObj((prev: any) => ({
        ...prev,
        [name]: value,
      }));
    }
  };
  const previewImages = (files: File[]) => {
    const imagePreviews: string[] = [];
    const promises = files.map((file, i) => {
      if (i >= 5 - formObj.preview.length) {
        return Promise.resolve();
      }
      return new Promise<void>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          if (e.target) {
            imagePreviews.push(e.target.result as string);
            resolve();
          } else {
            reject(new Error("FileReader error"));
          }
        };
        reader.onerror = () => reject(new Error("FileReader error"));
        reader.readAsDataURL(file);
      });
    });

    Promise.all(promises)
      .then(() => {
        setformObj((prev: any) => ({
          ...prev,
          preview: [...prev.preview, ...imagePreviews],
        }));
      })
      .catch((error) => {
        console.error("Error reading files:", error);
        setErrorObj((prev) => ({
          ...prev,
          file: "Error reading files",
        }));
      });
  };
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  // const handleChracter = (data) => {
  //   setErrorObj(defaultErrorValues);
  //   setformObj((prev) => ({ ...prev, characters: data }));
  // };
  // console.log(8877, seasonList);
  return (
    <Row>
      <Col lg={9}>
        <Row>
          <Col lg={6} className="mt-5">
            <>
              <FormGroup className="form-group">
                <FormLabel className="mb-1">Select Cover Image</FormLabel>
                <FormControl
                  className="form-control"
                  name="coverImage"
                  type="file"
                  ref={fileInputRef}
                  multiple={false}
                  accept="image/jpeg, image/png"
                  onChange={handleInputChange}
                  style={{ display: "none" }}
                />
              </FormGroup>
              {formObj.preview.length < 1 && !formObj.coverImage && (
                <Button onClick={handleButtonClick}>
                  <svg width="21" height="22" viewBox="0 0 21 22" fill="none">
                    <path
                      d="M19.8625 10.376L10.9447 19.2938C9.85225 20.3862 8.37051 21 6.8255 21C5.28048 21 3.79874 20.3862 2.70625 19.2938C1.61376 18.2013 1 16.7195 1 15.1745C1 13.6295 1.61376 12.1477 2.70625 11.0553L11.624 2.1375C12.3523 1.40917 13.3402 1 14.3702 1C15.4002 1 16.388 1.40917 17.1163 2.1375C17.8447 2.86583 18.2538 3.85365 18.2538 4.88366C18.2538 5.91367 17.8447 6.9015 17.1163 7.62983L8.18888 16.5476C7.82471 16.9118 7.3308 17.1163 6.81579 17.1163C6.30079 17.1163 5.80687 16.9118 5.44271 16.5476C5.07855 16.1834 4.87396 15.6895 4.87396 15.1745C4.87396 14.6595 5.07855 14.1656 5.44271 13.8014L13.6812 5.57263"
                      stroke="#fff"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Button>
              )}
              {errorObj.coverImage && (
                <div className="error">Select cover image</div>
              )}
            </>

            {formObj.preview.length > 0 ? (
              <div className="imagesDel mb-5">
                {formObj.preview.map((item, i) => {
                  return (
                    <div className="imgItems" key={i}>
                      <Image src={item} alt="" className="img-fluid" />
                      <div
                        className="pointer cross"
                        
                        onClick={() => handleSingleImageDelete(i)}
                      >
                        <svg
                          width="21"
                          height="20"
                          viewBox="0 0 21 20"
                          fill="none"
                        >
                          <rect
                            x="0.985107"
                            width="19.0448"
                            height="20"
                            rx="6"
                            fill="#FB8624"
                          />
                          <path
                            d="M14.1259 6.99976L7.65063 13.7998M7.65063 6.99976L14.1259 13.7998"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              formObj.coverImage && (
                <div className="imagesDel mb-5">
                  <div className="imgItems">
                    <Image
                      src={
                        process.env.REACT_APP_CLOUDFRONT_URL +
                        formObj.coverImage
                      }
                      alt=""
                      className="img-fluid"
                    />
                    <div
                      className="cross pointer"
                      onClick={() => handleSingleImageDelete(0)}
                    >
                      <svg
                        width="21"
                        height="20"
                        viewBox="0 0 21 20"
                        fill="none"
                      >
                        <rect
                          x="0.985107"
                          width="19.0448"
                          height="20"
                          rx="6"
                          fill="#FB8624"
                        />
                        <path
                          d="M14.1259 6.99976L7.65063 13.7998M7.65063 6.99976L14.1259 13.7998"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              )
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
