import axios from "axios";
import { Upload } from "tus-js-client";
export const IMAGE_URL = process.env.REACT_APP_CLOUDFRONT_URL;
export const validateFields = async (validationSchema: any, values: any) => {
  try {
    await validationSchema.validate(values, { abortEarly: false });
    return {};
  } catch (validationErrors: any) {
    const errors = {};
    validationErrors.inner.forEach((error: any) => {
      if (error.path) {
        errors[error.path] = error.message;
      }
    });
    return errors;
  }
};
export const Helper = {
  PostData: async (url, obj) => {
    try {
      return await axios.post(url, obj, {
        headers: {
          Authorization: `Bearer ${localStorage.getItemItem("token")}`,
          "Content-Type": "application/json",
        },
      });
    } catch (err: any) {
      console.log(err.response);
      if (err.response.data.message === "BLOCKED")
        return window.location.replace("/no-auth");
      return err;
    }
  },
  PutData: async (url, obj) => {
    try {
      return await axios.put(url, obj, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          device_token: localStorage.getItem("device_token"),
          "Content-Type": "application/json",
        },
      });
    } catch (err: any) {
      console.error(err);
      if (err.response.data.message === "BLOCKED")
        return window.location.replace("/no-auth");
      return err;
    }
  },
  PatchData: async (url, obj) => {
    try {
      return await axios.patch(url, obj, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          device_token: localStorage.getItem("device_token"),
          "Content-Type": "application/json",
        },
      });
    } catch (err: any) {
      console.log(err);
      if (err.response?.data?.message === "BLOCKED")
        return window.location.replace("/no-auth");
      return err;
    }
  },
  GetData: async (url) => {
    try {
      const result = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          device_token: localStorage.getItem("device_token"),
          "Content-Type": "application/json",
        },
      });
      return result;
    } catch (err: any) {
      if (err.response.data.message === "BLOCKED")
        return window.location.replace("/no-auth");
      return err;
    }
  },
  PostFormData: async (url, obj) => {
    try {
      return await axios.post(url, obj, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          device_token: localStorage.getItem("device_token"),
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (err: any) {
      console.log(err);
      if (err.response.data.message === "BLOCKED")
        return window.location.replace("/no-auth");
      return err;
    }
  },
  PatchFormData: async (url, obj) => {
    try {
      return await axios.patch(url, obj, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          device_token: localStorage.getItem("device_token"),
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (err: any) {
      console.log(err);
      if (err.response.data.message === "BLOCKED")
        return window.location.replace("/no-auth");
      return err;
    }
  },
  PutFormData: async (url, obj) => {
    try {
      return await axios.put(url, obj, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          device_token: localStorage.getItem("device_token"),
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (err: any) {
      console.log(err);
      if (err.response.data.message === "BLOCKED")
        return window.location.replace("/no-auth");
      return err;
    }
  },
  DeleteData: async (url) => {
    try {
      return await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          device_token: localStorage.getItem("device_token"),
          "Content-Type": "application/json",
        },
      });
    } catch (err: any) {
      console.log(err);
      if (err.response.data.message === "BLOCKED")
        return window.location.replace("/no-auth");
      return err;
    }
  },
};

export const getVideoDuration = (file) => {
  return new Promise((resolve, reject) => {
    const videoElement = document.createElement("video");
    const fileURL = URL.createObjectURL(file);

    videoElement.src = fileURL;

    videoElement.onloadedmetadata = () => {
      const duration = videoElement.duration;
      URL.revokeObjectURL(fileURL);
      resolve(duration);
    };
  });
};
export const createVimeoUploadSession = async (
  file,
  folder_id,
  title,
  description
) => {
  console.log("working upload",{file,folder_id,title,description})
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_VIMEOSECRET}`,
        "Content-Type": "application/json",
      },
    };
    console.log(config);
    const response = await fetch("https://api.vimeo.com/me/videos", {
      method: "POST",
      body: JSON.stringify({
        upload: {
          approach: "tus",
          size: file.size,
        },
        name: title,
        description: description,
        folder_id: folder_id,
      }),
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_VIMEOSECRET}`,
        "Content-Type": "application/json",
      },
    }).then((res) => res.json());

    const duration = await getVideoDuration(file);
    return { response, duration };
  } catch (error) {
    console.error("Error creating upload session:", error);
    throw error;
  }
};
export const createVimeoReplaceSession = async (
  file,
  // folder_id,
  title,
  description,
  video_id
) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_VIMEOSECRET}`,
        "Content-Type": "application/json",
      },
    };
    console.log(config);
    // https://api.vimeo.com/videos/{video_id}/versions
    const response = await fetch(
      `https://api.vimeo.com/videos/${video_id}/versions`,
      {
        method: "POST",
        body: JSON.stringify({
          file_name: title,
          upload: {
            approach: "tus",
            size: file.size,
            status: "in_progress",
          },
          // name: title,
          // description: description,
          // folder_id: folder_id,
        }),
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_VIMEOSECRET}`,
          "Content-Type": "application/json",
        },
      }
    ).then((res) => res.json());

    const duration = await getVideoDuration(file);
    return { response, duration };
  } catch (error) {
    console.error("Error creating upload session:", error);
    throw error;
  }
};
export const handleUploadClick = async (
  file,
  folder_id,
  title,
  description,
  setformObj,
  progress,
  lang
) => {
  console.log("working upload");
  return new Promise(async (resolve, reject) => {
    try {
      const res = await createVimeoUploadSession(
        file,
        folder_id,
        title,
        description
      );
      console.log("res", res);
      const { response: uploadData, duration } = res;
      const upload = new Upload(file, {
        // endpoint: uploadLink.url,
        uploadUrl: uploadData.upload.upload_link,
        metadata: {
          filename: file.name,
          filetype: file.type,
        },
        onError: (error) => {
          console.error("Upload failed:", error);
        },
        onProgress: (bytesUploaded, bytesTotal) => {
          const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
          setformObj((prev) => ({
            ...prev,
            [progress]: {
              ...prev[progress],
              [lang]: percentage,
            },
          }));
        },
        onSuccess: () => {
          // console.log("Upload successful:", duration);
          resolve({ uploadData, duration });
        },
        chunkSize: 1e8,
      });

      upload.start();
    } catch (error) {
      console.error("Error starting upload:", error);
      reject(error);
    }
  });
};
export const handleReplaceClick = async (
  file,
  // folder_id,
  title,
  description,
  setformObj,
  progress,
  lang,
  video_id
) => {
  console.log("working");
  return new Promise(async (resolve, reject) => {
    try {
      const res = await createVimeoReplaceSession(
        file,
        // folder_id,
        title,
        description,
        video_id
      );

      const { response: uploadData, duration } = res;
      const upload = new Upload(file, {
        // endpoint: uploadLink.url,
        uploadUrl: uploadData.upload.upload_link,
        metadata: {
          filename: file.name,
          filetype: file.type,
        },
        onError: (error) => {
          console.error("Upload failed:", error);
        },
        onProgress: (bytesUploaded, bytesTotal) => {
          const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
          console.log(percentage);
          setformObj((prev) => ({
            ...prev,
            [progress]: {
              ...prev[progress],
              [lang]: percentage,
            },
          }));
        },
        onSuccess: () => {
          // console.log("Upload successful:", duration);
          resolve({ uploadData, duration });
        },
        chunkSize: 1e8,
      });

      upload.start();
    } catch (error) {
      console.error("Error starting upload:", error);
      reject(error);
    }
  });
};
export const extractMovieCode = (string: String) => {
  const str = string?.split("/");
  return str[str.length - 1];
};

export function secondsToTime(secs: number) {
  var hours = Math.floor(secs / (60 * 60));

  var divisor_for_minutes = secs % (60 * 60);
  var minutes = Math.floor(divisor_for_minutes / 60);

  var divisor_for_seconds = divisor_for_minutes % 60;
  var seconds = Math.ceil(divisor_for_seconds);

  var obj = {
    h: hours,
    m: minutes,
    s: seconds,
  };
  return obj;
}
