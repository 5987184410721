import React, { useState } from "react";
import { PageLink, PageTitle } from "../../../../../../_metronic/layout/core";
import { AddCharForm } from "./AddCharForm";
import { Button, Tab, Tabs } from "react-bootstrap";
import * as yup from "yup";
import { validateFields } from "../../../../../../services/helper";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../../../redux/hooks";
import toast from "react-hot-toast";
import {
  addCharacterService,
  editCharacterService,
  editRevisionService,
} from "../../../../../../services/user";
// import { addCharacterService } from "../../../../services/user";

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: "Characters",
    path: "/characters",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];
const defaultValues = [
  {
    name: {
      en: "",
      ar: "",
      tr: "",
    },
    description: {
      en: "",
      ar: "",
      tr: "",
    },
    file: [],
    preview: [],
  },
];
const defaultErrorValues = [
  {
    name: {
      en: false,
      ar: false,
      tr: false,
    },
    description: {
      en: false,
      ar: false,
      tr: false,
    },
    file: false,
  },
];
const languageSchema = yup.object().shape({
  en: yup.string().trim().required("Enter character value in english"),
  ar: yup.string().trim().required("Enter character value in arabic"),
  tr: yup.string().trim().required("Enter character value in turkish"),
});
const defaultValuesSchema = yup.array().of(
  yup.object().shape({
    name: languageSchema,
    description: languageSchema,
    images: yup.mixed(),
    file: yup
      .array()
      .of(yup.mixed().required("File is required"))
      .when("images", (images, schema) => {
        console.log("testter", images);
        if (!images[0])
          return schema.min(1, "At least one file is required").required();
        return schema.optional();
      }),
  })
);
const AddCharacterComponent = ({
  setStep,
  setCharObj,
  onSubmit,
  movieId,
  charObj,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [errorObj, setErrorObj] = useState(defaultErrorValues);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState("en");
  const handleSelect = (key: any) => {
    setTab(key);
  };

 
  const handleUpdate = async (e: any) => {
    e.preventDefault();
    console.log("clicked");
    const errors: any = await validateFields(defaultValuesSchema, charObj);
    // if (Object.keys(errors).length > 0) {
    //   for (let i = 0; i < charObj.length; i++) {
    //     if (errors[`[${i}].name.en`] || errors[`[${i}].description.en`]) {
    //       setTab("en");
    //       break;
    //     } else if (
    //       errors[`[${i}].name.ar`] ||
    //       errors[`[${i}].description.ar`]
    //     ) {
    //       setTab("ar");
    //       break;
    //     } else if (
    //       errors[`[${i}].name.tr`] ||
    //       errors[`[${i}].description.tr`]
    //     ) {
    //       setTab("tr");
    //       break;
    //     }
    //   }
    //   setLoading(false);
    //   return setErrorObj(errors);
    // }

    const formData = new FormData();
    for (const key in charObj) {
      // if (charObj.hasOwnProperty(key)) {
      if (key !== "preview" && key !== "file") {
        formData.append(
          key,
          typeof charObj[key] === "object"
            ? JSON.stringify(charObj[key])
            : charObj[key]
        );
      } else if (key === "file") {
        if (charObj.file && charObj.file.length > 0) {
          charObj.file.forEach((file: Blob) => {
            formData.append("images", file);
          });
        }
      }
      // }
    }
    try {
      const result = await editRevisionService(formData);
      if (result.data.status) {
        navigate(-1);
        // toast.success(result.data.message);
        // Optionally navigate or perform other actions here for each successful upload
      } else {
        toast.error(result.data.message);
      }
    } catch (err) {
      console.error(err);
      toast.error("An error occurred while editing the character.");
    }
  };
  console.log({ charObj });
  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>"Edit Character"</PageTitle>
      <Tabs
        activeKey={tab}
        id="uncontrolled-tab-example"
        className="mb-3"
        onSelect={handleSelect}
      >
        <Tab
          eventKey="en"
          title={<div style={{ width: "6rem" }}>EN</div>}
        ></Tab>
        <Tab
          eventKey="ar"
          title={<div style={{ width: "6rem" }}>AR</div>}
        ></Tab>
        <Tab
          eventKey="tr"
          title={<div style={{ width: "6rem" }}>TR</div>}
        ></Tab>
      </Tabs>

      <AddCharForm
        lang={tab}
        formObj={charObj}
        setformObj={setCharObj}
        errorObj={errorObj}
        setErrorObj={setErrorObj}
        defaultErrorValues={defaultErrorValues}
      />

      <Button className="mt-5 btn" onClick={() => navigate(-1)}>
        Cancel
      </Button>
      <Button
        className="mt-5 ms-5 btn"
        disabled={loading}
        onClick={handleUpdate}
      >
        Update
      </Button>
    </>
  );
};

export default AddCharacterComponent;
