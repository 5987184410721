import axios from "axios";
import { AuthModel, UserModel } from "./_models";
import { instance } from "../../../../services/config";

// const API_URL = process.env.REACT_APP_API_URL
const API_URL = process.env.REACT_APP_SERVER;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/profile`;
export const LOGIN_URL = `${API_URL}/admin/login`;
export const REGISTER_URL = `${API_URL}/vendorSingup`;
export const OTP_URL = `${API_URL}/verify-otp`;
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`;

// Server should return AuthModel
export function login(username: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    username,
    password,
  });
}

// Server should return AuthModel
export function register(
  email: string,
  name: string,
  phone: string,
  password: string
) {
  return axios.post(REGISTER_URL, {
    email,
    name,
    phone,
    password,
  });
}
export function verifyOtp(
  hash: string,
  username: string,
  otp: string
) {
  return axios.post<AuthModel>(OTP_URL, {
    hash,
    username,
    otp
  });
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  });
}

export function getUserByToken() {
  return instance.get<any>(GET_USER_BY_ACCESSTOKEN_URL);
}
