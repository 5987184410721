import React, { useRef } from "react";
import {
  Row,
  Col,
  FormGroup,
  FormLabel,
  FormControl,
  Button,
} from "react-bootstrap";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { useAuth } from "../../../../../auth";

export const AddMovieForm = ({
  lang,
  formObj,
  setformObj,
  errorObj,
  setErrorObj,
  defaultErrorValues,
  movieId,
  setDelObj,
}) => {
  // const [previewObj, setPreviewObj] = useState(defaultValues);
  const { currentUser } = useAuth();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const fileInputRef1 = useRef<HTMLInputElement>(null);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorObj(defaultErrorValues);
    const { name, files } = event.target;
    if (files && files[0]) {
      const file = files[0];
      const fileURL = URL.createObjectURL(file);
      if (![".mp4", ".mov", ".webm"].some((i) => file?.name?.includes(i))) {
        toast.error(
          "File format not supported. Only MP4, MOV, and WEBM are supported."
        );
        return;
      }
      setformObj((prev) => ({
        ...prev,
        [name]: {
          ...prev[name],
          [lang]: file,
        },
        [name === "movie" ? "moviePreview" : "trailerPreview"]: {
          ...prev[name === "movie" ? "moviePreview" : "trailerPreview"],
          [lang]: fileURL,
        },
      }));
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      if (fileInputRef1.current) {
        fileInputRef1.current.value = "";
      }
    }
  };
  const handleSingleImageDelete = async (type: string, url?: string) => {
    setErrorObj(defaultErrorValues);
    if (type === "trailer") {
      if (movieId && url) {
        setformObj((prev) => ({
          ...prev,
          trailerUrl: {
            ...prev.trailerUrl,
            [lang]: null,
          },
        }));
        setDelObj((prev) => ({
          ...prev,
          trailerUrl: {
            ...prev.trailerUrl,
            [lang]: url,
          },
        }));
      } else {
        setformObj((prev) => ({
          ...prev,
          trailerPreview: {
            ...prev.trailerPreview,
            [lang]: null,
          },
          trailer: {
            ...prev.trailer,
            [lang]: null,
          },
        }));
      }
    } else if (type === "movie") {
      if (movieId && url) {
        setformObj((prev) => ({
          ...prev,
          videoUrl: {
            ...prev.videoUrl,
            [lang]: null,
          },
        }));
        setDelObj((prev) => ({
          ...prev,
          videoUrl: {
            ...prev.videoUrl,
            [lang]: url,
          },
        }));
      } else {
        setformObj((prev) => ({
          ...prev,
          moviePreview: {
            ...prev.moviePreview,
            [lang]: null,
          },
          movie: {
            ...prev.movie,
            [lang]: null,
          },
        }));
      }
    }
  };
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleTrailerButtonClick = () => {
    if (fileInputRef1.current) {
      fileInputRef1.current.click();
    }
  };
  return (
    <Row>
      <Col lg={9}>
        <Row>
          {(!movieId || (currentUser?.role !== "vendor" && movieId)) && (
            <Col lg={12}>
              <h3 style={{ color: "#8e8e8e" }}>Title: {formObj.title.en}</h3>
            </Col>
          )}
          <Col lg={6}>
            <>
              <FormGroup className="form-group mt-3">
                <FormLabel className="mb-1">Select Movie Trailer</FormLabel>
                <FormControl
                  className="form-control"
                  name="trailer"
                  type="file"
                  ref={fileInputRef1}
                  multiple={false}
                  accept=".mp4,.mkv,.avi,.mov,.wmv,.flv,.webm"
                  onChange={handleInputChange}
                  style={{ display: "none" }}
                />
              </FormGroup>
              <div className="card-upload">
                <div className="card-content">
                  {formObj.trailerPreview?.[lang] ||
                  formObj.trailerUrl?.[lang] ? (
                    <div className="video-preview">
                      <video width="100%" controls>
                        <source
                          src={
                            formObj?.trailerUrl?.[lang]
                              ? process.env.REACT_APP_CLOUDFRONT_URL +
                                formObj.trailerUrl[lang]
                              : formObj.trailerPreview[lang]
                          }
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                      <div
                        className="delete-icon"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleSingleImageDelete(
                            "trailer",
                            formObj?.trailerUrl?.[lang]
                          )
                        }
                      >
                        <svg
                          width="21"
                          height="20"
                          viewBox="0 0 21 20"
                          fill="none"
                        >
                          <rect
                            x="0.985107"
                            width="19.0448"
                            height="20"
                            rx="6"
                            fill="#FB8624"
                          />
                          <path
                            d="M14.1259 6.99976L7.65063 13.7998M7.65063 6.99976L14.1259 13.7998"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  ) : (
                    <Button
                      onClick={handleTrailerButtonClick}
                      className="upload-button mb-5"
                    >
                      <svg
                        width="21"
                        height="22"
                        viewBox="0 0 21 22"
                        fill="none"
                      >
                        <path
                          d="M19.8625 10.376L10.9447 19.2938C9.85225 20.3862 8.37051 21 6.8255 21C5.28048 21 3.79874 20.3862 2.70625 19.2938C1.61376 18.2013 1 16.7195 1 15.1745C1 13.6295 1.61376 12.1477 2.70625 11.0553L11.624 2.1375C12.3523 1.40917 13.3402 1 14.3702 1C15.4002 1 16.388 1.40917 17.1163 2.1375C17.8447 2.86583 18.2538 3.85365 18.2538 4.88366C18.2538 5.91367 17.8447 6.9015 17.1163 7.62983L8.18888 16.5476C7.82471 16.9118 7.3308 17.1163 6.81579 17.1163C6.30079 17.1163 5.80687 16.9118 5.44271 16.5476C5.07855 16.1834 4.87396 15.6895 4.87396 15.1745C4.87396 14.6595 5.07855 14.1656 5.44271 13.8014L13.6812 5.57263"
                          stroke="#fff"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Button>
                  )}
                </div>
              </div>
              {/* {formObj.trailerProgress[lang]=== 0 && (
                <ProgressBar
                  completed={formObj.trailerProgress[lang]}
                  barContainerClassName="barContainer"
                />
              )} */}
              {/* {formObj.trailerProgress[lang] > 0 && (
                <div
                  className="mt-2 progress h-6px"
                  style={{ width: "300px", backgroundColor: "#d3d3d3" }}
                >
                  <div
                    className="progress-bar bg-primary"
                    role="progressbar"
                    style={{ width: formObj.trailerProgress[lang] + "%" }}
                    // formObj.trailerProgress[lang]
                  ></div>
                </div>
              )} */}
              {errorObj[`trailer.${lang}`] && (
                <div className="error">Select movie trailer</div>
              )}
            </>
          </Col>
          <Col lg={6}>
            <>
              <FormGroup className="form-group mt-3">
                <FormLabel className="mb-1">Select Movie</FormLabel>
                <FormControl
                  className="form-control"
                  name="movie"
                  type="file"
                  ref={fileInputRef}
                  multiple={false}
                  accept=".mp4,.mkv,.avi,.mov,.wmv,.flv,.webm"
                  onChange={handleInputChange}
                  style={{ display: "none" }}
                />
              </FormGroup>
              <div className="card-upload">
                <div className="card-content">
                  {formObj.moviePreview?.[lang] || formObj.videoUrl?.[lang] ? (
                    <div className="video-preview">
                      <video width="100%" controls>
                        <source
                          src={
                            formObj?.videoUrl?.[lang]
                              ? process.env.REACT_APP_CLOUDFRONT_URL +
                                formObj.videoUrl[lang]
                              : formObj.moviePreview[lang]
                          }
                        />
                        Your browser does not support the video tag.
                      </video>
                      <div
                        className="delete-icon"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleSingleImageDelete(
                            "movie",
                            formObj?.videoUrl?.[lang]
                          )
                        }
                      >
                        <svg
                          width="21"
                          height="20"
                          viewBox="0 0 21 20"
                          fill="none"
                        >
                          <rect
                            x="0.985107"
                            width="19.0448"
                            height="20"
                            rx="6"
                            fill="#FB8624"
                          />
                          <path
                            d="M14.1259 6.99976L7.65063 13.7998M7.65063 6.99976L14.1259 13.7998"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  ) : (
                    <Button
                      onClick={handleButtonClick}
                      className="upload-button mb-5"
                    >
                      <svg
                        width="21"
                        height="22"
                        viewBox="0 0 21 22"
                        fill="none"
                      >
                        <path
                          d="M19.8625 10.376L10.9447 19.2938C9.85225 20.3862 8.37051 21 6.8255 21C5.28048 21 3.79874 20.3862 2.70625 19.2938C1.61376 18.2013 1 16.7195 1 15.1745C1 13.6295 1.61376 12.1477 2.70625 11.0553L11.624 2.1375C12.3523 1.40917 13.3402 1 14.3702 1C15.4002 1 16.388 1.40917 17.1163 2.1375C17.8447 2.86583 18.2538 3.85365 18.2538 4.88366C18.2538 5.91367 17.8447 6.9015 17.1163 7.62983L8.18888 16.5476C7.82471 16.9118 7.3308 17.1163 6.81579 17.1163C6.30079 17.1163 5.80687 16.9118 5.44271 16.5476C5.07855 16.1834 4.87396 15.6895 4.87396 15.1745C4.87396 14.6595 5.07855 14.1656 5.44271 13.8014L13.6812 5.57263"
                          stroke="#fff"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Button>
                  )}
                </div>
              </div>
              {/* {formObj.movieProgress[lang] > 0 && (
                <div
                  className="mt-2 progress h-6px"
                  style={{ width: "300px", backgroundColor: "#d3d3d3" }}
                >
                  <div
                    className="progress-bar bg-primary"
                    role="progressbar"
                    style={{ width: formObj.movieProgress[lang] + "%" }}
                  ></div>
                </div>
              )} */}
              {errorObj[`movie.${lang}`] && (
                <div className="error">Select movie</div>
              )}
            </>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
