import React, { useRef, useState } from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  FormLabel,
  InputGroup,
  FormControl,
  Image,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";

const defaultValue = {
  name: {
    en: "",
    ar: "",
    tr: "",
  },
  description: {
    en: "",
    ar: "",
    tr: "",
  },
  file: [],
  preview: [],
};

export const AddForm = ({
  lang,
  formObj,
  setformObj,
  errorObj,
  setErrorObj,
  defaultErrorValues,
}) => {
  const fileInputRef = useRef<any>([]);
  // const handleSingleImageDelete = async (index: any) => {
  //   setErrorObj(defaultErrorValues);
  //   const updated = formObj.preview.filter((item, i) => i !== index);
  //   const updatedFile = [...formObj.file];
  //   updatedFile.splice(index, 1);
  //   setformObj((prev) => ({ ...prev, file: updatedFile, preview: updated }));
  //   if (fileInputRef.current) {
  //     fileInputRef.current.value = "";
  //   }
  // };
  const handleSingleImageDelete = (formIndex, imageIndex) => {
    setErrorObj(defaultErrorValues);
    setformObj((prev) =>
      prev.map((ele, i) =>
        i === formIndex
          ? {
              ...ele,
              file: ele.file.filter((_, idx) => idx !== imageIndex),
              preview: ele.preview.filter((_, idx) => idx !== imageIndex),
            }
          : ele
      )
    );
    if (fileInputRef.current[formIndex]) {
      fileInputRef.current[formIndex].value = "";
    }
  };
  const handleImageDelete = (formIndex, imageIndex) => {
    setErrorObj(defaultErrorValues);
    setformObj((prev) =>
      prev.map((ele, i) =>
        i === formIndex
          ? {
              ...ele,
              images: ele.images.filter((_, idx) => idx !== imageIndex)
            }
          : ele
      )
    );
    if (fileInputRef.current[formIndex]) {
      fileInputRef.current[formIndex].value = "";
    }
  };
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index
  ) => {
    setErrorObj(defaultErrorValues);
    const { name, value } = event.target;
    if (name === "image") {
      const selectedFile = Array.from(event.target.files || []);
      if (selectedFile.length > 5 - formObj[index].file.length) {
        setErrorObj((prev: any) => ({
          ...prev,
          file: "You can not select more than 5 files",
        }));
        return;
      }
      if (selectedFile) {
        setformObj((prevFormObj) =>
          prevFormObj.map((ele, i) =>
            i === index ? { ...ele, file: [...ele.file, ...selectedFile] } : ele
          )
        );
        previewImages(selectedFile, index);
      }
    } else {
      setformObj((prevFormObj) =>
        prevFormObj.map((ele, i) =>
          i === index
            ? { ...ele, [name]: { ...ele[name], [lang]: value } }
            : ele
        )
      );
    }
  };
  const previewImages = (files: File[], index) => {
    const imagePreviews: string[] = [];
    const promises = files.map((file, i) => {
      if (i >= 5 - formObj[index].preview.length) {
        return Promise.resolve();
      }
      return new Promise<void>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          if (e.target) {
            imagePreviews.push(e.target.result as string);
            resolve();
          } else {
            reject(new Error("FileReader error"));
          }
        };
        reader.onerror = () => reject(new Error("FileReader error"));
        reader.readAsDataURL(file);
      });
    });

    Promise.all(promises)
      .then(() => {
        setformObj((prevFormObj) =>
          prevFormObj.map((ele, i) =>
            i === index
              ? { ...ele, preview: [...ele.preview, ...imagePreviews] }
              : ele
          )
        );
      })
      .catch((error) => {
        console.error("Error reading files:", error);
        setErrorObj((prev) => ({
          ...prev,
          file: "Error reading files",
        }));
      });
  };
  const handleButtonClick = (index) => {
    if (fileInputRef.current[index]) {
      fileInputRef.current[index].click();
    }
  };
  const handleAdd = () => {
    setformObj((prev) => [...prev, defaultValue]);
  };
  const handleDel = (index) => {
    const updatearr = formObj.map((ele, i) => {
      if (i === index) {
        return {
          ...ele,
          deleted: true,
        };
      } else {
        return ele;
      }
    });
    setformObj(updatearr);
  };
  console.log("formobj", formObj);
  return (
    <Row>
      <Col lg={9}>
        <Form>
          {formObj?.map(
            (ele, i) =>
              !ele.deleted && (
                <>
                  <Row>
                    <Col lg={9}>
                      <FormGroup className="form-group">
                        <FormLabel className="mb-1">Name</FormLabel>
                        <FormControl
                          className="form-control"
                          name="name"
                          type="text"
                          value={ele.name[lang]}
                          placeholder="Enter character name"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleInputChange(e, i)
                          }
                        />
                        {errorObj[`[${i}].name.${lang}`] && (
                          <div className="error">Enter character name</div>
                        )}
                      </FormGroup>
                      <FormGroup className="form-group mt-3">
                        <FormLabel className="mb-1">Description</FormLabel>
                        <FormControl
                          className="form-control"
                          value={ele.description[lang]}
                          name="description"
                          as="textarea"
                          rows={6}
                          placeholder="Enter character description"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleInputChange(e, i)
                          }
                        />
                        {errorObj[`[${i}].description.${lang}`] && (
                          <div className="error">
                            Enter character description
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg={3} className="text-end mt-3">
                      {i === 0 ? (
                        <Button onClick={handleAdd}>+</Button>
                      ) : (
                        <Button onClick={() => handleDel(i)}>-</Button>
                      )}
                    </Col>
                  </Row>

                  <FormGroup className="form-group mt-3">
                    <FormLabel className="mb-1">Select Images</FormLabel>
                    <FormControl
                      className="form-control"
                      name="image"
                      type="file"
                      ref={(el) => (fileInputRef.current[i] = el)}
                      multiple={true}
                      accept="image/jpeg, image/png"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChange(e, i)
                      }
                      style={{ display: "none" }}
                    />
                  </FormGroup>
                  <Button onClick={() => handleButtonClick(i)}>
                    <svg width="21" height="22" viewBox="0 0 21 22" fill="none">
                      <path
                        d="M19.8625 10.376L10.9447 19.2938C9.85225 20.3862 8.37051 21 6.8255 21C5.28048 21 3.79874 20.3862 2.70625 19.2938C1.61376 18.2013 1 16.7195 1 15.1745C1 13.6295 1.61376 12.1477 2.70625 11.0553L11.624 2.1375C12.3523 1.40917 13.3402 1 14.3702 1C15.4002 1 16.388 1.40917 17.1163 2.1375C17.8447 2.86583 18.2538 3.85365 18.2538 4.88366C18.2538 5.91367 17.8447 6.9015 17.1163 7.62983L8.18888 16.5476C7.82471 16.9118 7.3308 17.1163 6.81579 17.1163C6.30079 17.1163 5.80687 16.9118 5.44271 16.5476C5.07855 16.1834 4.87396 15.6895 4.87396 15.1745C4.87396 14.6595 5.07855 14.1656 5.44271 13.8014L13.6812 5.57263"
                        stroke="#fff"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Button>
                  {errorObj[`[${i}].file`] && (
                    <div className="error">Select character image</div>
                  )}
                  {
                    <div className="imagesDel mb-5">
                      {ele?.images?.map((item, index) => {
                        return (
                          <div className="imgItems" key={i}>
                            <Image
                              src={process.env.REACT_APP_CLOUDFRONT_URL + item}
                              alt=""
                              className="img-fluid"
                            />
                            <div
                              className="pointer cross"
                              // to={"#"}
                              onClick={() => handleImageDelete(i, index)}
                            >
                              <svg
                                width="21"
                                height="20"
                                viewBox="0 0 21 20"
                                fill="none"
                              >
                                <rect
                                  x="0.985107"
                                  width="19.0448"
                                  height="20"
                                  rx="6"
                                  fill="#fb8624"
                                />
                                <path
                                  d="M14.1259 6.99976L7.65063 13.7998M7.65063 6.99976L14.1259 13.7998"
                                  stroke="white"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                          </div>
                        );
                      })}
                      {
                        ele?.preview?.length > 0 &&
                          // <div className="imagesDel">
                          ele?.preview?.map((item, index) => {
                            return (
                              <div className="imgItems" key={i}>
                                <Image
                                  src={item}
                                  alt=""
                                  className="img-fluid"
                                />
                                <Link
                                  to={"#"}
                                  onClick={() =>
                                    handleSingleImageDelete(i, index)
                                  }
                                >
                                  <svg
                                    width="21"
                                    height="20"
                                    viewBox="0 0 21 20"
                                    fill="none"
                                  >
                                    <rect
                                      x="0.985107"
                                      width="19.0448"
                                      height="20"
                                      rx="6"
                                      fill="#fb8624"
                                    />
                                    <path
                                      d="M14.1259 6.99976L7.65063 13.7998M7.65063 6.99976L14.1259 13.7998"
                                      stroke="white"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </Link>
                              </div>
                            );
                          })
                        // </div>
                      }
                    </div>
                  }
                </>
              )
          )}
        </Form>
      </Col>
    </Row>
  );
};
