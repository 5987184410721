import {
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  Form,
} from "react-bootstrap";
import { useAppDispatch } from "../redux/hooks";
import { versionApproveService } from "../../services/movies";
import { FormEvent, useState } from "react";

const MailModal = ({ show, setShow }) => {
  const handleClose = () => setShow(false);

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        {/* <Modal.Header closeButton>
              <Modal.Title className="w-100 text-center">Delete Movie</Modal.Title>
            </Modal.Header> */}
        <Modal.Body>
          <div className="w-100 text-center fw-bold fs-3">
            You can't edit movie!
          </div>
          <div className="w-100 text-center text-danger fw-bold">
            Please contact ShowOk
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center gap-3">
          <Button
            variant="secondary"
            onClick={() =>
              (window.location.href =
                "mailto:contact@showok.net?subject=Update Movie Request")
            }
          >
            Contact
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MailModal;
