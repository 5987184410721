import axios, { AxiosResponse } from "axios";
import { refreshTokenService } from "./user";
import { AUTH_LOCAL_STORAGE_KEY } from "../app/modules/auth";

const baseUrl = process.env.REACT_APP_SERVER;
export const vimeoPlayerUrl = "https://player.vimeo.com/video/";
export const vimeoUrl = "https://vimeo.com/";

const instance = axios.create({
  baseURL: baseUrl,
});

const fileInstance = axios.create({
  baseURL: baseUrl,
});
const publicInstance = axios.create({
  baseURL: baseUrl,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  if (config.headers != null) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

const onRefresh = async () => {
  try {
    const token = localStorage.getItem("refreshToken");
    const res = await refreshTokenService({
      refreshToken: token,
    });
    if (res.data.status) {
      localStorage.setItem("token", res.data.token);
      localStorage.setItem("refreshToken", res.data.refresh);
      localStorage.setItem(
        AUTH_LOCAL_STORAGE_KEY,
        JSON.stringify({ token: res.data.token })
      );

      return { token: res.data.token };
    }
  } catch (e) {
    localStorage.removeItem("token");
    localStorage.removeItem("kt-auth-react-v");
    localStorage.removeItem("refreshToken");
    window.location.href = "/login";
    console.log(e);
  }
};
instance.interceptors.response.use(
  async function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  async function (error) {
    const originalRequest = error.response?.config;
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    try {
      if (!error.response?.data?.status && error?.response?.status === 401) {
        originalRequest._retry = true;
        const res = await onRefresh();

        if (res?.token) {
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + res?.token;
          fileInstance.defaults.headers[
            "Authorization"
          ] = `Bearer ${res?.token}`;
          instance.defaults.headers["Authorization"] = `Bearer ${res?.token}`;
          return instance(originalRequest);
        } else {
          localStorage.removeItem("token");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("kt-auth-react-v");
          return Promise.reject(error);
        }
      } else {
        return Promise.reject(error);
      }
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  }
);
fileInstance.interceptors.response.use(
  async function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  async function (error) {
    const originalRequest = error.response?.config;
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    try {
      if (!error.response?.data?.status && error?.response?.status === 401) {
        originalRequest._retry = true;
        const res = await onRefresh();
        if (res?.token) {
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + res?.token;
          fileInstance.defaults.headers[
            "Authorization"
          ] = `Bearer ${res?.token}`;
          instance.defaults.headers["Authorization"] = `Bearer ${res?.token}`;
          return instance(originalRequest);
        } else {
          localStorage.removeItem("token");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("kt-auth-react-v");
          return Promise.reject(error);
        }
      } else {
        return Promise.reject(error);
      }
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  }
);

fileInstance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");

  if (config.headers != null) {
    config.headers["Authorization"] = `Bearer ${token}`;
    config.headers["Content-Type"] = "multipart/form-data";
  }
  return config;
});

export { instance, fileInstance, publicInstance };
