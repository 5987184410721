import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  FormGroup,
  FormLabel,
  FormControl,
  Image,
  Button,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TagsInput } from "react-tag-input-component";
import { MultiSelect } from "react-multi-select-component";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../../../../redux/hooks";
import toast from "react-hot-toast";
import axios from "axios";
import moment from "moment";

interface DatePickerProps {
  selected?: Date | null;
  onChange: (date: Date | null) => void;
  showYearPicker?: boolean;
  dateFormat: string;
  placeholderText: string;
  className?: string;
}

export const AddForm = ({
  lang,
  formObj,
  setformObj,
  errorObj,
  setErrorObj,
  defaultErrorValues,
  movieId,
}) => {
  const [options, setOptions] = useState([]);
  const [characterOptions, setCharacterOptions] = useState([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const posterRef = useRef<HTMLInputElement>(null);
  const { genreList, characterList, exchangeRate } = useAppSelector(
    (state) => state.management
  );
  useEffect(() => {
    const result = genreList?.map((item) => ({
      label: item?.title?.en,
      value: item?._id,
    }));
    setOptions(result);
  }, [genreList]);
  useEffect(() => {
    const result = characterList?.map((item) => ({
      label: item?.name?.en,
      value: item?._id,
    }));
    setCharacterOptions(result);
  }, [characterList]);
  const handleSingleImageDelete = async (name: any) => {
    setErrorObj(defaultErrorValues);
    setformObj((prev) => ({
      ...prev,
      ...(name === "coverImage"
        ? { coverImage: null, preview: [] }
        : { posterImage: null, posterPreview: [] }),
    }));
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    if (posterRef.current) {
      posterRef.current.value = "";
    }
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorObj(defaultErrorValues);
    const { name, value } = event.target;
    if (name === "coverImage" || name === "posterImage") {
      const selectedFile = Array.from(event.target.files || []);
      if (selectedFile) {
        if (
          !["image/jpeg", "image/jpg", "image/png"].includes(
            selectedFile![0]?.type
          )
        ) {
          setErrorObj({
            ...errorObj,
            [name]: "Invalid file format",
          });
          return;
        }
        setformObj((prev: any) => ({
          ...prev,
          [name]: selectedFile,
        }));
        previewImages(selectedFile, name);
      }
    } else if (name === "releaseYear") {
      const filteredValue = value.replace(/[^0-9]/g, "");
      setformObj((prev) => ({
        ...prev,
        [name]: filteredValue,
      }));
    } else {
      setformObj((prev: any) => ({
        ...prev,
        [name]: value,
      }));
    }
  };
  const previewImages = (files: File[], name: string) => {
    const imagePreviews: string[] = [];
    const promises = files.map((file, i) => {
      if (i >= 5 - formObj.preview.length) {
        return Promise.resolve();
      }
      return new Promise<void>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          if (e.target) {
            imagePreviews.push(e.target.result as string);
            resolve();
          } else {
            reject(new Error("FileReader error"));
          }
        };
        reader.onerror = () => reject(new Error("FileReader error"));
        reader.readAsDataURL(file);
      });
    });

    Promise.all(promises)
      .then(() => {
        setformObj((prev: any) => ({
          ...prev,
          ...(name === "coverImage"
            ? { preview: [imagePreviews] }
            : { posterPreview: [imagePreviews] }),
        }));
      })
      .catch((error) => {
        console.error("Error reading files:", error);
        setErrorObj((prev) => ({
          ...prev,
          file: "Error reading files",
        }));
      });
  };
  const handleButtonClick = (ref) => {
    if (ref.current) {
      ref.current.click();
    }
  };
  const handleGenre = (data) => {
    setErrorObj(defaultErrorValues);
    setformObj((prev) => ({ ...prev, genres: data }));
  };
  // const handleChracter = (data) => {
  //   setErrorObj(defaultErrorValues);
  //   setformObj((prev) => ({ ...prev, characters: data }));
  // };
  const handleDateChange = (date: Date | null) => {
    if (date) {
      setformObj({
        ...formObj,
        releaseYear: date.getFullYear().toString(),
      });
    } else {
      setformObj({
        ...formObj,
        releaseYear: "",
      });
    }
  };
  const thisYear = new Date().getFullYear();
  const myDatePickerProps: any = {
    selected: formObj.releaseYear ? new Date(formObj.releaseYear, 0, 1) : null,
    onChange: (date) => handleDateChange(date),
    showYearPicker: true,
    dateFormat: "YYYY",
    placeholderText: "Select a release year",
    className: "form-control",
    maxDate: new Date(thisYear, 11, 31),
  };
  console.log({formObj});
  return (
    options && (
      <Row>
        <Col lg={9}>
          <Row>
            {/* <Col lg={6}>
              <FormGroup className="form-group">
                <FormLabel className="mb-1">Languages</FormLabel>
                <TagsInput
                  value={formObj.languages}
                  onChange={handleLanguage}
                  placeHolder="Enter languages"
                  classNames={{
                    input: "tag-input",
                  }}
                />
                {errorObj[`languages`] && (
                  <div className="error">Enter languages</div>
                )}
              </FormGroup>
            </Col> */}
            <Col lg={6}>
              <FormGroup className="form-group">
                <FormLabel className="mb-1">Release Year</FormLabel>
                <DatePicker
                  {...myDatePickerProps}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
                {errorObj[`releaseYear`] && (
                  <div className="error">Enter valid release year</div>
                )}
              </FormGroup>
            </Col>
            <Col lg={6}>
              <FormGroup className="form-group">
                <FormLabel className="mb-1">Genres</FormLabel>
                <MultiSelect
                  options={options}
                  value={formObj.genres}
                  onChange={handleGenre}
                  labelledBy="Select"
                  hasSelectAll={false}
                />
                {errorObj[`genres`] && (
                  <div className="error">Select genre</div>
                )}
              </FormGroup>
            </Col>
            {/* <Col lg={6} className="mt-5">
              <FormGroup className="form-group">
                <FormLabel className="mb-1">Characters</FormLabel>
                <MultiSelect
                  options={characterOptions}
                  value={formObj.characters}
                  onChange={handleChracter}
                  labelledBy="Select"
                  hasSelectAll={false}
                />
                {errorObj[`characters`] && (
                  <div className="error">Select characters</div>
                )}
              </FormGroup>
            </Col> */}
            <Col lg={6} className="mt-5">
              <FormGroup className="form-group">
                <FormLabel className="mb-1">Rent Price</FormLabel>
                <FormControl
                  className="form-control"
                  name="rentPrice"
                  type="number"
                  value={formObj.rentPrice}
                  placeholder="Enter rent price"
                  onChange={handleInputChange}
                />
                {errorObj[`rentPrice`] && (
                  <div className="error">Enter rent price</div>
                )}
              </FormGroup>
            </Col>
            <Col lg={6} className="mt-5">
              <FormGroup className="form-group">
                <FormLabel className="mb-1">Rent Price (in SAR)</FormLabel>
                <FormControl
                  className="form-control"
                  name="sarRentPrice"
                  type="number"
                  // disabled
                  value={formObj.sarRentPrice}
                  placeholder="Enter sar rent year"
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
          </Row>
          {/* <Col lg={6}>
            <FormGroup className="form-group">
              <FormLabel className="mb-1">Duration (in minutes)</FormLabel>
              <TagsInput
                value={formObj.languages}
                onChange={handleLanguage}
                placeHolder="Enter languages"
                classNames={{
                  input:"tag-input"
                }}
              />
              {errorObj[`languages`] && (
                <div className="error">Enter languages</div>
              )}
            </FormGroup>
          </Col> */}
          {
            <Row>
              <Col lg={6} className="mt-5">
                <>
                  <FormGroup className="form-group">
                    <FormLabel className="mb-1">Cover Image</FormLabel>
                    <FormControl
                      className="form-control"
                      name="coverImage"
                      type="file"
                      ref={fileInputRef}
                      multiple={false}
                      accept="image/jpeg, image/png"
                      onChange={handleInputChange}
                      style={{ display: "none" }}
                    />
                  </FormGroup>
                  {formObj.preview.length < 1 && !formObj?.coverImage && (
                    <Button onClick={() => handleButtonClick(fileInputRef)}>
                      <svg
                        width="21"
                        height="22"
                        viewBox="0 0 21 22"
                        fill="none"
                      >
                        <path
                          d="M19.8625 10.376L10.9447 19.2938C9.85225 20.3862 8.37051 21 6.8255 21C5.28048 21 3.79874 20.3862 2.70625 19.2938C1.61376 18.2013 1 16.7195 1 15.1745C1 13.6295 1.61376 12.1477 2.70625 11.0553L11.624 2.1375C12.3523 1.40917 13.3402 1 14.3702 1C15.4002 1 16.388 1.40917 17.1163 2.1375C17.8447 2.86583 18.2538 3.85365 18.2538 4.88366C18.2538 5.91367 17.8447 6.9015 17.1163 7.62983L8.18888 16.5476C7.82471 16.9118 7.3308 17.1163 6.81579 17.1163C6.30079 17.1163 5.80687 16.9118 5.44271 16.5476C5.07855 16.1834 4.87396 15.6895 4.87396 15.1745C4.87396 14.6595 5.07855 14.1656 5.44271 13.8014L13.6812 5.57263"
                          stroke="#fff"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Button>
                  )}
                  {errorObj.coverImage && (
                    <div className="error">
                      {errorObj.coverImage || "Select cover image"}
                    </div>
                  )}
                </>

                {formObj.preview.length > 0 ? (
                  <div className="imagesDel mb-5">
                    {formObj.preview.map((item, i) => {
                      return (
                        <div className="imgItems" key={i}>
                          <Image src={item} alt="" className="img-fluid" />
                          <div
                            className="pointer cross"
                            onClick={() =>
                              handleSingleImageDelete("coverImage")
                            }
                          >
                            <svg
                              width="21"
                              height="20"
                              viewBox="0 0 21 20"
                              fill="none"
                            >
                              <rect
                                x="0.985107"
                                width="19.0448"
                                height="20"
                                rx="6"
                                fill="#FB8624"
                              />
                              <path
                                d="M14.1259 6.99976L7.65063 13.7998M7.65063 6.99976L14.1259 13.7998"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  formObj?.coverImage && (
                    <div className="imagesDel mb-5">
                      <div className="imgItems">
                        <Image
                          src={
                            process.env.REACT_APP_CLOUDFRONT_URL +
                            formObj?.coverImage
                          }
                          alt=""
                          className="img-fluid"
                        />
                        <div
                          className="pointer cross"
                          onClick={() => handleSingleImageDelete("coverImage")}
                        >
                          <svg
                            width="21"
                            height="20"
                            viewBox="0 0 21 20"
                            fill="none"
                          >
                            <rect
                              x="0.985107"
                              width="19.0448"
                              height="20"
                              rx="6"
                              fill="#FB8624"
                            />
                            <path
                              d="M14.1259 6.99976L7.65063 13.7998M7.65063 6.99976L14.1259 13.7998"
                              stroke="white"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </Col>
              <Col lg={6} className="mt-5">
                <>
                  <FormGroup className="form-group">
                    <FormLabel className="mb-1">Poster Image</FormLabel>
                    <FormControl
                      className="form-control"
                      name="posterImage"
                      type="file"
                      ref={posterRef}
                      multiple={false}
                      accept="image/jpeg, image/png"
                      onChange={handleInputChange}
                      style={{ display: "none" }}
                    />
                  </FormGroup>
                  {formObj.posterPreview.length < 1 && !formObj.posterImage && (
                    <Button onClick={() => handleButtonClick(posterRef)}>
                      <svg
                        width="21"
                        height="22"
                        viewBox="0 0 21 22"
                        fill="none"
                      >
                        <path
                          d="M19.8625 10.376L10.9447 19.2938C9.85225 20.3862 8.37051 21 6.8255 21C5.28048 21 3.79874 20.3862 2.70625 19.2938C1.61376 18.2013 1 16.7195 1 15.1745C1 13.6295 1.61376 12.1477 2.70625 11.0553L11.624 2.1375C12.3523 1.40917 13.3402 1 14.3702 1C15.4002 1 16.388 1.40917 17.1163 2.1375C17.8447 2.86583 18.2538 3.85365 18.2538 4.88366C18.2538 5.91367 17.8447 6.9015 17.1163 7.62983L8.18888 16.5476C7.82471 16.9118 7.3308 17.1163 6.81579 17.1163C6.30079 17.1163 5.80687 16.9118 5.44271 16.5476C5.07855 16.1834 4.87396 15.6895 4.87396 15.1745C4.87396 14.6595 5.07855 14.1656 5.44271 13.8014L13.6812 5.57263"
                          stroke="#fff"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Button>
                  )}
                  {errorObj.posterImage && (
                    <div className="error">
                      {errorObj.posterImage || "Select poster image"}
                    </div>
                  )}
                </>

                {formObj.posterPreview.length > 0 ? (
                  <div className="imagesDel mb-5">
                    {formObj.posterPreview.map((item, i) => {
                      return (
                        <div className="imgItems" key={i}>
                          <Image src={item} alt="" className="img-fluid" />
                          <Link
                            to={"#"}
                            onClick={() =>
                              handleSingleImageDelete("posterImage")
                            }
                          >
                            <svg
                              width="21"
                              height="20"
                              viewBox="0 0 21 20"
                              fill="none"
                            >
                              <rect
                                x="0.985107"
                                width="19.0448"
                                height="20"
                                rx="6"
                                fill="#FB8624"
                              />
                              <path
                                d="M14.1259 6.99976L7.65063 13.7998M7.65063 6.99976L14.1259 13.7998"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  formObj.posterImage && (
                    <div className="imagesDel mb-5">
                      <div className="imgItems">
                        <Image
                          src={
                            process.env.REACT_APP_CLOUDFRONT_URL +
                            formObj.posterImage
                          }
                          alt=""
                          className="img-fluid"
                        />
                        <div
                          className="pointer cross"
                          onClick={() => handleSingleImageDelete("posterImage")}
                        >
                          <svg
                            width="21"
                            height="20"
                            viewBox="0 0 21 20"
                            fill="none"
                          >
                            <rect
                              x="0.985107"
                              width="19.0448"
                              height="20"
                              rx="6"
                              fill="#FB8624"
                            />
                            <path
                              d="M14.1259 6.99976L7.65063 13.7998M7.65063 6.99976L14.1259 13.7998"
                              stroke="white"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </Col>

              {/* {formObj.preview.length > 0 && (
              
            )} */}
            </Row>
          }
        </Col>
      </Row>
    )
  );
};
