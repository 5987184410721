import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import {
  MixedWidget11,
  StatisticsWidget5,
} from "../../../_metronic/partials/widgets";
import { useCallback, useEffect } from "react";
import {
  getDashboardChartThunk,
  getDashboardThunk,
  getVendorDashboardChartThunk,
  getVendorDashboardThunk,
} from "../../redux/thunks/user";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import moment from "moment";
import { getMonths, monthNames } from "../../../_metronic/assets/ts";
import {
  getCharacterThunk,
  getExchangeThunk,
  getGenreThunk,
  getMovieListThunk,
  getSeriesListThunk,
  getTransactionListThunk,
  getUserListThunk,
  getVendorsListThunk,
} from "../../redux/thunks/management";
import { useAuth } from "../../modules/auth";
var start = new Date(new Date().getTime() - 180 * 24 * 60 * 60 * 1000);
var startDate = new Date(start.getFullYear(), start.getMonth(), 1);
const DashboardPage = ({ dashboard, chartData, role }) => {
  const getSeries = () => {
    let arr: any[] = [];

    let categories = getMonths(start, new Date()).map(
      (i) => `${monthNames[i.month]}-${i.year}`
    );

    let usersObj = {};
    let salesObj = {};

    categories.map((i) => {
      usersObj[i] = chartData?.users?.[0]?.data[i] || 0;
    });
    categories.map((i) => {
      salesObj[i] = chartData?.sales?.[0]?.data[i] || 0;
    });
    arr.push({
      name: "Users",
      data: Object.values(usersObj),
    });
    arr.push({
      name: "Transactions",
      data: Object.values(salesObj),
    });
    return arr;
  };

  return (
    <>
      {/* begin::Row */}
      <div className="row g-5 g-xl-8">
        <div className="col-xl-4">
          {role === "super-admin" ? (
            <StatisticsWidget5
              className="card-xl-stretch mb-xl-8"
              svgIcon="user"
              color="body-white"
              iconColor="primary"
              description="User(s)"
              title={dashboard?.users || 0}
              titleColor="gray-900"
              descriptionColor="gray-800"
            />
          ) : (
            <StatisticsWidget5
              className="card-xl-stretch mb-xl-8"
              svgIcon="picture"
              color="body-white"
              iconColor="primary"
              description="Content(s)"
              title={dashboard?.users || 0}
              titleColor="gray-900"
              descriptionColor="gray-800"
            />
          )}
        </div>

        <div className="col-xl-4">
          <StatisticsWidget5
            className="card-xl-stretch mb-xl-8"
            svgIcon="cheque"
            color="primary"
            iconColor="white"
            description="Total Sales"
            title={
              ("SAR ") + (dashboard?.sales.toFixed(2) || 0)
            }
            titleColor="white"
            descriptionColor="white"
          />
        </div>

        <div className="col-xl-4">
          <StatisticsWidget5
            className="card-xl-stretch mb-5 mb-xl-8"
            svgIcon="left"
            color="dark"
            iconColor="gray-100"
            title="Transactions"
            description={`${(dashboard?.transactions || 0)?.toFixed(
              2
            )}% increase in transactions from previous month`}
            titleColor="gray-100"
            descriptionColor="gray-100"
          />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className="row">
        <div className="col-xxl-12">
          <MixedWidget11
            totalSales={
             (("SAR ") + (chartData?.totalSalesOfYear && chartData?.totalSalesOfYear?.length > 0
                ? String(chartData?.totalSalesOfYear[0]?.total.toFixed(2))
                : String(0)))
            }
            series={getSeries()}
            className="card-xxl-stretch-50 mb-5 mb-xl-8"
            chartColor="primary"
            chartHeight="175px"
            fromString={`${moment(startDate).format(
              "MMM YYYY"
            )} - ${moment().format("MMM YYYY")}`}
            categories={getMonths(startDate, new Date()).map(
              (i) => `${moment().month(i.month).format("MMM")} - ${i.year}`
            )}
          />
        </div>
      </div>
      {/* end::Row */}
    </>
  );
};

const DashboardWrapper = () => {
  const { currentUser } = useAuth();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { dashboard, chartData } = useAppSelector((state) => state.auth);

  const getDashboard = useCallback(() => {
    if (currentUser?.role === "super-admin") {
      dispatch(getDashboardThunk());
      dispatch(getDashboardChartThunk());
      dispatch(getUserListThunk("?page=1"));
      dispatch(getVendorsListThunk("?page=1"));
      dispatch(getMovieListThunk("?page=1&type=movie"));
      dispatch(getSeriesListThunk("?page=1&type=series"));
      dispatch(getTransactionListThunk("?page=1"));
      dispatch(getGenreThunk("?page=1"));
      dispatch(getCharacterThunk("?page=1"));
      dispatch(getExchangeThunk());
    } else {
      dispatch(getVendorDashboardThunk());
      dispatch(getVendorDashboardChartThunk());
      dispatch(getMovieListThunk("?page=1&type=movie"));
      dispatch(getSeriesListThunk("?page=1&type=series"));
      dispatch(getTransactionListThunk("?page=1"));
      dispatch(getGenreThunk("?page=1"));
      dispatch(getExchangeThunk());
    }
  }, [dispatch]);

  useEffect(() => {
    if (dashboard === undefined || currentUser) getDashboard();
  }, [getDashboard, currentUser]);

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.DASHBOARD" })}
      </PageTitle>
      <DashboardPage
        dashboard={dashboard}
        chartData={chartData}
        role={currentUser?.role}
      />
    </>
  );
};

export { DashboardWrapper };
