import React, { useRef, useState } from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  FormLabel,
  InputGroup,
  FormControl,
  Image,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";

const defaultValue = {
  name: {
    en: "",
    ar: "",
    tr: "",
  },
  description: {
    en: "",
    ar: "",
    tr: "",
  },
  file: [],
  preview: [],
};

export const AddCharForm = ({
  lang,
  formObj,
  setformObj,
  errorObj,
  setErrorObj,
  defaultErrorValues,
}) => {
  const fileInputRef = useRef<any>([]);
  const handleSingleImageDelete = async (index: any) => {
    setErrorObj(defaultErrorValues);
    const updated = formObj.preview.filter((item, i) => i !== index);
    const updatedFile = [...formObj.file];
    updatedFile.splice(index, 1);
    setformObj((prev) => ({ ...prev, file: updatedFile, preview: updated }));
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };
  const handleImageDelete = async (index: any) => {
    setErrorObj(defaultErrorValues);
    const updatedFile = [...formObj.images];
    updatedFile.splice(index, 1);
    setformObj((prev) => ({ ...prev, images: updatedFile }));
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorObj(defaultErrorValues);
    const { name, value } = event.target;
    if (name === "image") {
      const selectedFile = Array.from(event.target.files || []);
      if (selectedFile.length > 5 - formObj.file.length) {
        setErrorObj((prev: any) => ({
          ...prev,
          file: "You can not select more than 5 files",
        }));
        return;
      }
      if (selectedFile) {
        setformObj((prevFormObj) => ({
          ...prevFormObj,
          file: [...prevFormObj.file, ...selectedFile],
        }));

        previewImages(selectedFile);
      }
    } else {
      setformObj((prevFormObj) => ({
        ...prevFormObj,
        [name]: { ...prevFormObj[name], [lang]: value },
      }));
    }
  };
  const previewImages = (files: File[]) => {
    const imagePreviews: string[] = [];
    const promises = files.map((file, i) => {
      if (i >= 5 - formObj.preview.length) {
        return Promise.resolve();
      }
      return new Promise<void>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          if (e.target) {
            imagePreviews.push(e.target.result as string);
            resolve();
          } else {
            reject(new Error("FileReader error"));
          }
        };
        reader.onerror = () => reject(new Error("FileReader error"));
        reader.readAsDataURL(file);
      });
    });

    Promise.all(promises)
      .then(() => {
        setformObj((prevFormObj) => ({
          ...prevFormObj,
          preview: [...prevFormObj.preview, ...imagePreviews],
        }));
      })
      .catch((error) => {
        console.error("Error reading files:", error);
        setErrorObj((prev) => ({
          ...prev,
          file: "Error reading files",
        }));
      });
  };
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  
  console.log("formobj", formObj);
  return (
    <Row>
      <Col lg={9}>
        <Form>
          <>
            <Row>
              <Col lg={9}>
                <FormGroup className="form-group">
                  <FormLabel className="mb-1">Name</FormLabel>
                  <FormControl
                    className="form-control"
                    name="name"
                    type="text"
                    value={formObj.name[lang]}
                    placeholder="Enter character name"
                    onChange={handleInputChange}
                  />
                  {/* {errorObj[`[${i}].name.${lang}`] && (
                    <div className="error">Enter character name</div>
                  )} */}
                </FormGroup>
                <FormGroup className="form-group mt-3">
                  <FormLabel className="mb-1">Description</FormLabel>
                  <FormControl
                    className="form-control"
                    value={formObj.description[lang]}
                    name="description"
                    as="textarea"
                    rows={6}
                    placeholder="Enter character description"
                    onChange={handleInputChange}
                  />
                  {/* {errorObj[`[${i}].description.${lang}`] && (
                    <div className="error">Enter character description</div>
                  )} */}
                </FormGroup>
              </Col>
            </Row>

            <FormGroup className="form-group mt-3">
              <FormLabel className="mb-1">Select Images</FormLabel>
              <FormControl
                className="form-control"
                name="image"
                type="file"
                ref={fileInputRef}
                multiple={true}
                accept="image/jpeg, image/png"
                onChange={handleInputChange}
                style={{ display: "none" }}
              />
            </FormGroup>
            <Button onClick={handleButtonClick}>
              <svg width="21" height="22" viewBox="0 0 21 22" fill="none">
                <path
                  d="M19.8625 10.376L10.9447 19.2938C9.85225 20.3862 8.37051 21 6.8255 21C5.28048 21 3.79874 20.3862 2.70625 19.2938C1.61376 18.2013 1 16.7195 1 15.1745C1 13.6295 1.61376 12.1477 2.70625 11.0553L11.624 2.1375C12.3523 1.40917 13.3402 1 14.3702 1C15.4002 1 16.388 1.40917 17.1163 2.1375C17.8447 2.86583 18.2538 3.85365 18.2538 4.88366C18.2538 5.91367 17.8447 6.9015 17.1163 7.62983L8.18888 16.5476C7.82471 16.9118 7.3308 17.1163 6.81579 17.1163C6.30079 17.1163 5.80687 16.9118 5.44271 16.5476C5.07855 16.1834 4.87396 15.6895 4.87396 15.1745C4.87396 14.6595 5.07855 14.1656 5.44271 13.8014L13.6812 5.57263"
                  stroke="#fff"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Button>
            {/* {errorObj[`[${i}].file`] && (
              <div className="error">Select character image</div>
            )} */}
            {
              <div className="imagesDel mb-5">
                {formObj?.images?.map((item,index) => {
                  return (
                    <div className="imgItems" key={index}>
                      <Image
                        src={process.env.REACT_APP_CLOUDFRONT_URL + item}
                        alt=""
                        className="img-fluid"
                      />
                      <div className="cross pointer"
                          onClick={() => handleImageDelete(index)}
                        >
                          <svg
                            width="21"
                            height="20"
                            viewBox="0 0 21 20"
                            fill="none"
                          >
                            <rect
                              x="0.985107"
                              width="19.0448"
                              height="20"
                              rx="6"
                              fill="#fb8624"
                            />
                            <path
                              d="M14.1259 6.99976L7.65063 13.7998M7.65063 6.99976L14.1259 13.7998"
                              stroke="white"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                    </div>
                  );
                })}
                {
                  formObj?.preview?.length > 0 &&
                    // <div className="imagesDel">
                    formObj?.preview?.map((item, index) => {
                      return (
                        <div className="imgItems" key={index}>
                          <Image src={item} alt="" className="img-fluid" />
                          <div className="cross pointer"
                            onClick={() => handleSingleImageDelete(index)}
                          >
                            <svg
                              width="21"
                              height="20"
                              viewBox="0 0 21 20"
                              fill="none"
                            >
                              <rect
                                x="0.985107"
                                width="19.0448"
                                height="20"
                                rx="6"
                                fill="#fb8624"
                              />
                              <path
                                d="M14.1259 6.99976L7.65063 13.7998M7.65063 6.99976L14.1259 13.7998"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        </div>
                      );
                    })
                  // </div>
                }
              </div>
            }
          </>
        </Form>
      </Col>
    </Row>
  );
};
