import { Navigate, Outlet, Route, Routes, useParams } from "react-router-dom";
import { PageLink, PageTitle } from "../../../../_metronic/layout/core";
import { Overview } from "./components/Overview";
import { ProfileHeader } from "./ProfileHeader";
import { useEffect, useState } from "react";
import { Transactions } from "./components/Transactions";
import { getMoviesByIdService } from "../../../../services/movies";

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: "Movies",
    path: "/movies",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];

const MovieDetails = () => {
  const params = useParams();
  const [movie, setMovie] = useState(null);
  const [totalTransactionsByUser, setTotalTransactionsByUser] = useState(0);

  useEffect(() => {
    getMovieById();
  }, []);
  
  const getMovieById = async () => {
    try {
      const res = await getMoviesByIdService(params.id);
      if (res.data.status) {
        setMovie(res.data.movie);
        setTotalTransactionsByUser(res.data.totalTransactions);
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Routes>
      <Route
        element={
          <>
            <ProfileHeader
              movie={movie}
              totalTransactions={totalTransactionsByUser}
            />
            <Outlet />
          </>
        }
      >
        <Route
          path="overview"
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>
                Movie Details
              </PageTitle>
              <Overview movie={movie} />
            </>
          }
        />
        <Route
          path="transaction"
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>
                Transactions
              </PageTitle>
              <Transactions />
            </>
          }
        />
        <Route
          index
          element={<Navigate to={"/movies" + `/${params.id}/overview`} />}
        />
      </Route>
    </Routes>
  );
};

export default MovieDetails;
