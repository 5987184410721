import { useEffect, useState } from "react";
import { PageLink, PageTitle } from "../../../../../../_metronic/layout/core";
import { AddLanguageForm } from "./form/AddLanguageForm";
import { Button, Tab, Tabs } from "react-bootstrap";
import * as yup from "yup";
import {
  extractMovieCode,
  handleReplaceClick,
  handleUploadClick,
  validateFields,
} from "../../../../../../services/helper";
import { editRevisionService } from "../../../../../../services/user";
import { useLocation, useNavigate } from "react-router-dom";
import { AddForm } from "./form/AddForm";
import toast from "react-hot-toast";
import Loader from "../../../../../../_metronic/layout/components/Loader";
import {
  getSeasonsService,
  getVersionDetailsService,
} from "../../../../../../services/movies";

// import { addCharacterService } from "../../../../services/user";

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: "Requests",
    path: "/requests",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];

const defaultErrLang = {
  en: false,
  ar: false,
  tr: false,
};
const defaultFile = {
  en: null,
  ar: null,
  tr: null,
};
const defaultLang = {
  en: "",
  ar: "",
  tr: "",
};
const defaultValues = {
  characters: [],
  title: defaultLang,
  description: defaultLang,
  coverImage: null,
  preview: [],
  trailer: defaultFile,
  trailerProgress: {
    en: 0,
    ar: 0,
    tr: 0,
  },
  videoUrl: defaultFile,
  trailerPreview: defaultFile,
};
const defaultErrorValues = {
  description: defaultErrLang,
  title: defaultErrLang,
  coverImage: false,
  characters: false,
  trailer: false,
  season: false,
};
const languageSchema = yup.object().shape({
  en: yup.string().required("Enter value in english"),
  ar: yup.string().required("Enter value in arabic"),
  tr: yup.string().required("Enter value in turkish"),
});
const fileSchema = yup.object().shape({
  en: yup.mixed().required("Enter value in english"),
  ar: yup.mixed().required("Enter value in arabic"),
  tr: yup.mixed().required("Enter value in turkish"),
});
const secondStepSchema = yup.object().shape({
  title: languageSchema,
  description: languageSchema,
  coverImage: yup.mixed().required("Cover image is required"),
});

const AddEpisode = ({ versionId }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const receivedData: any = location.state;
  const [formObj, setformObj] = useState(defaultValues);
  // const [charObj, setCharObj] = useState(defaultCharacterValues);
  const [errorObj, setErrorObj] = useState(defaultErrorValues);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [seasonList, setSeasons] = useState<any>(null);
  const [tab, setTab] = useState("en");
  const [avg, setAvg] = useState(0);
  const [step, setStep] = useState(1);
  const [seasonId, setSeasonId] = useState("search.get(");
  const [parentFolderId, setParentFolderId] = useState("search.get");
  const [seriesId, setSeriesId] = useState("seriesId");
  const [folderId, setFolderId] = useState(null);
  const [delObj, setDelObj] = useState({
    videoUrl: defaultFile,
  });
  const [count, setCount] = useState(3);

  useEffect(() => {
    const avg =
      (+formObj.trailerProgress.en +
        +formObj.trailerProgress.tr +
        +formObj.trailerProgress.ar) /
      count;
    setAvg(avg);
  }, [formObj]);
  useEffect(() => {
    getEpisode();
  }, []);
  const handleSelect = (key: any) => {
    setTab(key);
  };
  // console.log(9900,seriesId,seasonId)
  const handleEdit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    console.log(33333,formObj)
    try {
      const errors: any = await validateFields(secondStepSchema, formObj);
      if (!formObj.videoUrl.en && !formObj.trailer.en) {
        errors["trailer.en"] = "Select trailer in english";
      } else if (!formObj.videoUrl.ar && !formObj.trailer.ar) {
        errors["trailer.ar"] = "Select trailer in arabic";
      } else if (!formObj.videoUrl.tr && !formObj.trailer.tr) {
        errors["trailer.tr"] = "Select trailer in turkis";
      }
      if (Object.keys(errors).length > 0) {
        if (errors[`title.en`] || errors[`description.en`]) {
          setTab("en");
        } else if (errors[`title.ar`] || errors[`description.ar`]) {
          setTab("ar");
        } else if (errors[`title.tr`] || errors[`description.tr`]) {
          setTab("tr");
        }
        return setErrorObj(errors);
      }
      let cnt = 0;
      if (!formObj.videoUrl.ar) {
        cnt++;
      } else if (!formObj.videoUrl.tr) {
        cnt++;
      } else if (!formObj.videoUrl.en) {
        cnt++;
      }
      if (cnt > 0) {
        setCount(cnt);
      }
      const [ten, tar, ttr]: [any, any, any] = await Promise.allSettled([
        !formObj.videoUrl.en &&
          handleReplaceClick(
            formObj.trailer.en,
            "trailer_en",
            formObj.description.en,
            setformObj,
            "trailerProgress",
            "en",
            delObj.videoUrl.en
          ),
        !formObj.videoUrl.ar &&
          handleReplaceClick(
            formObj.trailer.ar,
            "trailer_ar",
            formObj.description.ar,
            setformObj,
            "trailerProgress",
            "ar",
            delObj.videoUrl.ar
          ),
        !formObj.videoUrl.tr &&
          handleReplaceClick(
            formObj.trailer.tr,
            "trailer_tr",
            formObj.description.tr,
            setformObj,
            "trailerProgress",
            "tr",
            delObj.videoUrl.tr
          ),
      ]);
      // const characters = formObj.characters.map((item: any) => item.value);
      const obj = {
        versionId,
        description: formObj.description,
        title: formObj.title,
        coverImage: formObj.coverImage,
        videoUrl: {
          en: ten.value
            ? extractMovieCode(ten.value.uploadData.uri)
            : formObj.videoUrl.en,
          ar: tar.value
            ? extractMovieCode(tar.value.uploadData.uri)
            : formObj.videoUrl.ar,
          tr: ttr.value
            ? extractMovieCode(ttr.value.uploadData.uri)
            : formObj.videoUrl.tr,
        },
      };
      const formData = new FormData();
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (key === "description" || key === "videoUrl" || key === "title") {
            formData.append(key, JSON.stringify(obj[key]));
          } else if (Array.isArray(obj[key])) {
            obj[key].forEach(function (value) {
              formData.append(key, value);
            });
          } else {
            formData.append(key, obj[key]);
          }
        }
      }
      const result = await editRevisionService(formData);
      if (result.data.status) {
        setformObj(defaultValues);
        toast.success(result.data.message);
        // navigate(-1);
        navigate("/requests", {
          replace: true,
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const getEpisode = async () => {
    const result = await getVersionDetailsService({ versionId });
    if (result.data.status) {
      const season = result.data.movie;
      const obj = {
        characters: season.characters,
        coverImage: season.coverImage,
        description: {
          en: season.description.en,
          ar: season.description.ar,
          tr: season.description.tr,
        },
        title: {
          en: season.title.en,
          ar: season.title.ar,
          tr: season.title.tr,
        },
        videoUrl: {
          en: season.videoUrl.en,
          ar: season.videoUrl.ar,
          tr: season.videoUrl.tr,
        },
      };
      setformObj((prev) => ({ ...prev, ...obj }));
    }
    setFetching(false);
  };

  return fetching ? (
    <Loader />
  ) : (
    // step===1?
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>Edit Episode</PageTitle>

      <AddForm
        lang={tab}
        formObj={formObj}
        setformObj={setformObj}
        errorObj={errorObj}
        setErrorObj={setErrorObj}
        defaultErrorValues={defaultErrorValues}
        seasonId={seasonId}
        setSeasonId={setSeasonId}
        setParenFolderId={setParentFolderId}
        seasonList={seasonList}
      />

      <Tabs
        activeKey={tab}
        id="uncontrolled-tab-example"
        className="my-5"
        onSelect={handleSelect}
      >
        <Tab
          eventKey="en"
          title={<div style={{ width: "6rem" }}>EN</div>}
        ></Tab>
        <Tab
          eventKey="ar"
          title={<div style={{ width: "6rem" }}>AR</div>}
        ></Tab>
        <Tab
          eventKey="tr"
          title={<div style={{ width: "6rem" }}>TR</div>}
        ></Tab>
      </Tabs>

      <AddLanguageForm
        lang={tab}
        formObj={formObj}
        setformObj={setformObj}
        errorObj={errorObj}
        setErrorObj={setErrorObj}
        defaultErrorValues={defaultErrorValues}
        setDelObj={setDelObj}
      />
      {avg > 0 && (
        <div
          className="mt-5 progress h-6px"
          style={{ width: "300px", backgroundColor: "#d3d3d3" }}
        >
          <div
            className="progress-bar bg-primary"
            role="progressbar"
            style={{ width: avg + "%" }}
          ></div>
        </div>
      )}

      <Button
        className="mt-5 btn"
        disabled={loading}
        onClick={() => navigate(-1)}
      >
        Cancel
      </Button>
      <Button className="mt-5 ms-5 btn" disabled={loading} onClick={handleEdit}>
        {loading ? "Updating" : "Update"}
      </Button>
    </>
  );
  // :(
  //   <AddCharacterComponent
  //     setStep={setStep}
  //     setCharObj={null}
  //     onSubmit={handleSubmit}
  //   />
  // );
};

export default AddEpisode;
