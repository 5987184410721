import { formatMinutes } from "../../../../../_metronic/assets/ts";
import { vimeoUrl } from "../../../../../services/config";
import { secondsToTime } from "../../../../../services/helper";
import { useAppSelector } from "../../../../redux/hooks";

export function Overview({ movie }: any) {
  const {exchangeRate} = useAppSelector((state)=>state.management);
  return (
    movie && (
      <div className="card">
        <div className="card-body py-3">
          <div className="d-flex">
            <h5 className="text-muted w-90px">Movie:</h5>
            {Object.keys(movie?.videoUrl)
              .filter((i) => i !== "_id")
              .map((i) => (
                <>
                  <span className="mx-3 text-uppercase">{`${i}: ${movie?.videoUrl[i]}`}</span>
                  <br></br>
                </>
              ))}
          </div>
          <div className="d-flex my-2">
            <h5 className="text-muted w-90px">Trailer:</h5>
            {Object.keys(movie?.trailerUrl)
              .filter((i) => i !== "_id")
              .map((i) => (
                <>
                  <span className="mx-3 text-uppercase">{`${i}: ${movie?.trailerUrl[i]}`}</span>
                  <br></br>
                </>
              ))}
          </div>
          <div className="d-flex">
            <h5 className="text-muted w-90px">Slug:</h5>
            <p className="mx-3">{movie?.slug}</p>
          </div>
          <div className="d-flex my-2">
            <h5 className="text-muted w-90px">Languages:</h5>
            <p className="mx-3">{movie?.languages?.join(", ")}</p>
          </div>
          <div className="d-flex my-2">
            <h5 className="text-muted w-90px">Genres:</h5>
            <p className="mx-3">{movie?.genres?.map(item=>item?.en).join(", ")}</p>
          </div>
          <div className="d-flex my-2">
            <h5 className="text-muted w-90px">Characters:</h5>
            <p className="mx-3">
              {movie?.characters?.map((item) => item?.name?.en).join(", ")}
            </p>
          </div>
          <div className="d-flex my-2">
            <h5 className="text-muted w-90px">Duration:</h5>
            <p className="mx-3">
              {" "}
              {secondsToTime(movie?.duration).h} hr{" "}
              {secondsToTime(movie?.duration).m} min
            </p>
          </div>
          <div className="d-flex my-2">
            <h5 className="text-muted w-90px">Release Year:</h5>
            <p className="mx-3">{movie?.releaseYear}</p>
          </div>
          <div className="d-flex my-2">
            <h5 className="text-muted w-90px">Rent Price:</h5>
            <p className="mx-3">$ {movie?.rentPrice}</p>
          </div>
          <div className="d-flex my-2">
            <h5 className="text-muted w-90px">SAR Rent Price:</h5>
            <p className="mx-3">{movie?.sarRentPrice}</p>
          </div>
          <div className="d-flex my-2">
            <h5 className="text-muted w-90px">Starring:</h5>
            <p className="mx-3">{movie?.starring?.join(", ") || "--"}</p>
          </div>
          <div className="d-flex my-2">
            <h5 className="text-muted w-90px">Created By:</h5>
            <p className="mx-3">{movie?.createdBy?.join(", ") || "--"}</p>
          </div>
          <div className="d-flex my-2">
            <h5 className="text-muted w-90px">Description:</h5>
            <p className="mx-3">{movie?.description?.en}</p>
          </div>
          <div className="d-flex my-2">
            <h5 className="text-muted w-90px">Meta Description:</h5>
            <p className="mx-3">
            {movie?.meta_description &&
                Object.keys(movie?.meta_description)
                  ?.filter((i) => i !== "_id")
                  ?.map((i) => (
                    <>
                      {`${i} - ${movie?.meta_description[i]}`}
                      <br></br>
                    </>
                  ))}
            </p>
          </div>
          <div className="d-flex my-2">
            <h5 className="text-muted w-90px">Meta Tags:</h5>
            <p className="mx-3">
            {movie?.meta_tags &&
                Object.keys(movie?.meta_tags)
                  ?.filter((i) => i !== "_id")
                  ?.map((i) => (
                    <>
                      {`${i} - ${movie?.meta_tags[i]}`}
                      <br></br>
                    </>
                  ))}
            </p>
          </div>
        </div>
      </div>
    )
  );
}
