/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { getUserByToken, login, register } from "../core/_requests";
import { Icon } from "@iconify/react";
import { useAuth } from "../core/Auth";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import "react-phone-number-input/style.css";
import toast from "react-hot-toast";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Enter email"),
  name: Yup.string().required("Enter name"),
  phone: Yup.string().required("Enter phone number"),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Enter password"),
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref("password"), ""],
      "Password and confirm password must match"
    )
    .required("Confirm password is required"),
  acceptTerms: Yup.boolean()
    .oneOf([true], "You must accept the terms and conditions")
    .required("You must accept the terms and conditions"),
});

const initialValues = {
  email: "",
  password: "",
  phone: "",
  confirmPassword: "",
  name: "",
  acceptTerms: false,
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function SignUp() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();
  const [isVisible, setVisible] = useState(false);
  const [isVisible1, setVisible1] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const { data } = await register(
          values.email,
          values.name,
          values.phone.slice(1),
          values.password
        );
        if (data.status) {
          sessionStorage.setItem("validEntry", "true");
          navigate("/auth/verify-otp", {
            state: { hash: data.hash, username: data.username },
          });
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        console.error(error);
        setStatus("The login details are incorrect");
      } finally {
        setSubmitting(false);
        setLoading(false);
      }
    },
  });
  const toggle = () => {
    setVisible(!isVisible);
  };
  const toggle1 = () => {
    setVisible1(!isVisible1);
  };
  return (
    <form
      className="form w-100 h-100"
      onSubmit={formik.handleSubmit}
      noValidate
      id="kt_login_signin_form"
    >
      {/* begin::Heading */}
      <div className="text-center mb-11">
        <h1 className="fw-bolder mb-3 text-white">Vendor Registration</h1>
        {/* <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div> */}
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      ) : null}
      {/* (
        <div className="mb-10 bg-light-info p-8 rounded">
          <div className="text-info">
            Use account <strong>admin@demo.com</strong> and password{" "}
            <strong>demo</strong> to continue.
          </div>
        </div>
      ) */}
      {/* begin::Form group */}
      <div className="fv-row mb-4">
        <label className="form-label fs-6 fw-bolder text-white">
          Full Name
        </label>
        <input
          placeholder="Full Name"
          {...formik.getFieldProps("name")}
          className={clsx(
            "form-control bg-transparent"
            // { "is-invalid": formik.touched.name && formik.errors.name },
            // {
            //   "is-valid": formik.touched.name && !formik.errors.name,
            // }
          )}
          type="text"
          name="name"
          autoComplete="off"
        />
        {formik.touched.name && formik.errors.name && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.name}</span>
            </div>
          </div>
        )}
      </div>
      <div className="fv-row mb-4">
        <label className="form-label fs-6 fw-bolder text-white">Email</label>
        <input
          placeholder="Email"
          {...formik.getFieldProps("email")}
          className={clsx(
            "form-control bg-transparent"
            // { "is-invalid": formik.touched.email && formik.errors.email },
            // {
            //   "is-valid": formik.touched.email && !formik.errors.email,
            // }
          )}
          type="email"
          name="email"
          autoComplete="new-email"
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      <div className="fv-row mb-4">
        <label className="form-label fs-6 fw-bolder text-white">
          Phone Number
        </label>
        <PhoneInputWithCountrySelect
          placeholder="Enter phone number"
          value={formik.values.phone}
          internationalIcon={InternationalIcon}
          onChange={(value) => formik.setFieldValue("phone", value)}
          onBlur={() => formik.setFieldTouched("phone")}
          autoComplete="off"
          className={clsx(
            "form-control bg-transparent"
            // {
            //   "is-invalid": formik.touched.phone && formik.errors.phone,
            // },
            // {
            //   "is-valid": formik.touched.phone && !formik.errors.phone,
            // }
          )}
        />
        {formik.touched.phone && formik.errors.phone && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.phone}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="fv-row mb-4 position-relative">
        <label className="form-label fw-bolder text-white fs-6 mb-0">
          Password
        </label>
        <input
          type={isVisible ? "text" : "password"}
          autoComplete="new-password"
          placeholder="Password"
          {...formik.getFieldProps("password")}
          className={clsx(
            "form-control bg-transparent"
            // {
            //   "is-invalid": formik.touched.password && formik.errors.password,
            // },
            // {
            //   "is-valid": formik.touched.password && !formik.errors.password,
            // }
          )}
        />
        <span className="absIcon absIcon1 eye" onClick={toggle}>
          {isVisible ? (
            <Icon icon="ri:eye-line" />
          ) : (
            <Icon icon="majesticons:eye-off-line" />
          )}
        </span>
        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      <div className="fv-row mb-6 position-relative">
        <label className="form-label fw-bolder text-white fs-6 mb-0">
          Confirm Password
        </label>
        <input
          type={isVisible1 ? "text" : "password"}
          autoComplete="off"
          placeholder="Password"
          {...formik.getFieldProps("confirmPassword")}
          className={clsx(
            "form-control bg-transparent"
            // {
            //   "is-invalid":
            //     formik.touched.confirmPassword && formik.errors.confirmPassword,
            // },
            // {
            //   "is-valid":
            //     formik.touched.confirmPassword &&
            //     !formik.errors.confirmPassword,
            // }
          )}
        />
        <span className="absIcon absIcon1 eye" onClick={toggle1}>
          {isVisible1 ? (
            <Icon icon="ri:eye-line" />
          ) : (
            <Icon icon="majesticons:eye-off-line" />
          )}
        </span>
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.confirmPassword}</span>
            </div>
          </div>
        )}
      </div>
      <div className="fv-row mb-6">
        <div className="d-flex gap-2">
          <input
            type="checkbox"
            {...formik.getFieldProps("acceptTerms")}
            className={clsx("form-check-input")}
          />
          <label className="form-check form-check-custom form-check-solid align-items-start">
            <span className="form-check-label text-white fs-6">
              I accept the{" "}
              <a href="#" className="text-decoration-none text-primary">
                terms and conditions
              </a>
            </span>
          </label>
        </div>

        {formik.touched.acceptTerms && formik.errors.acceptTerms && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.acceptTerms}</span>
            </div>
          </div>
        )}
      </div>

      {/* end::Form group */}
      {/* begin::Action */}
      <div className="d-grid mb-10">
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-primary"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className="indicator-label">Continue</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
      <div
        className="d-grid mb-10 primary-color text-center pointer"
        onClick={() => navigate("/auth")}
      >
        Back to login
      </div>
      {/* end::Action */}
    </form>
  );
}
const InternationalIcon = () => (
  <div style={{ marginTop: "-0.3rem" }}>
    <Icon
      icon="ph:phone-thin"
      width="20"
      height="20"
      style={{ color: "#cccccc" }}
    />
  </div>
);
