import React, { useEffect, useState } from "react";
import { PageLink, PageTitle } from "../../../../../../_metronic/layout/core";
import { AddLanguageForm } from "./form/AddLanguageForm";
import { Button, Tab, Tabs } from "react-bootstrap";
import * as yup from "yup";
import {
  extractMovieCode,
  handleReplaceClick,
  handleUploadClick,
  validateFields,
} from "../../../../../../services/helper";
import {
  addCharacterService,
  addMovieService,
  editMovieService,
  editRevisionService,
  getFolderService,
} from "../../../../../../services/user";
import { useLocation, useNavigate } from "react-router-dom";
import { AddForm } from "./form/AddForm";
import { AddMovieForm } from "./form/AddMovieForm";
import toast from "react-hot-toast";
import {
  fetchMoviesByIdService,
  getVersionDetailsService,
} from "../../../../../../services/movies";
import Loader from "../../../../../../_metronic/layout/components/Loader";
import moment from "moment";
import { useAuth } from "../../../../auth";

// import { addCharacterService } from "../../../../services/user";

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: "Requests",
    path: "/requests",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];

const defaultErrLang = {
  en: false,
  ar: false,
  tr: false,
};
const defaultFile = {
  en: null,
  ar: null,
  tr: null,
};
const defaultLang = {
  en: "",
  ar: "",
  tr: "",
};
const defaultValues = {
  genres: [],
  characters: [],
  title: defaultLang,
  // languages: [],
  duration: "",
  releaseYear: moment().format("YYYY"),
  rentPrice: "",
  sarRentPrice: "",
  description: defaultLang,
  coverImage: null,
  preview: [],
  starring: [],
  createdBy: [],
  trailer: defaultFile,
  movie: defaultFile,
  movieProgress: {
    en: 0,
    ar: 0,
    tr: 0,
  },
  trailerProgress: {
    en: 0,
    ar: 0,
    tr: 0,
  },
  moviePreview: defaultFile,
  trailerPreview: defaultFile,
  posterImage: null,
  posterPreview: [],
  trailerUrl: defaultFile,
  videoUrl: defaultFile,
};
const defaultErrorValues = {
  title: defaultErrLang,
  languages: false,
  description: defaultErrLang,
  coverImage: false,
  genres: false,
  characters: false,
  duration: false,
  releaseYear: false,
  rentPrice: false,
  sarRentPrice: false,
  movieUrl: defaultErrLang,
  trailerUrl: defaultErrLang,
  preview: false,
  starring: false,
  createdBy: false,
  trailer: defaultErrLang,
  movie: defaultErrLang,
};

const languageSchema = yup.object().shape({
  en: yup.string().required("Enter value in english"),
  ar: yup.string().required("Enter value in arabic"),
  tr: yup.string().required("Enter value in turkish"),
});
const fileSchema = yup.object().shape({
  en: yup.mixed().required("Enter value in english"),
  ar: yup.mixed().required("Enter value in arabic"),
  tr: yup.mixed().required("Enter value in turkish"),
});

const firstStepSchema = yup.object().shape({
  genres: yup
    .array()
    .of(yup.mixed())
    .min(1, "At least one language is required")
    .required("Languages are required"),
  // characters: yup
  //   .array()
  //   .of(yup.mixed())
  //   .min(1, "At least one language is required")
  //   .required("Languages are required"),
  title: languageSchema,
  // languages: yup
  //   .array()
  //   .of(yup.string())
  //   .min(1, "At least one language is required")
  //   .required("Languages are required"),
  releaseYear: yup
    .string()
    .required("Enter movie release year")
    .matches(/^\d{4}$/, "Release year must be a 4-digit number")
    .test(
      "maxYear",
      "Release year cannot be greater than currentYear",
      (value) => {
        const currentYear = new Date().getFullYear();
        return parseInt(value, 10) <= currentYear;
      }
    ),
  rentPrice: yup.string().required("Rent price is required"),
  sarRentPrice: yup.string().required("Sar rent price is required"),
  description: languageSchema,
  coverImage: yup.mixed().required("Cover image is required"),
});
const secondStepSchema = yup.object().shape({
  genres: yup
    .array()
    .of(yup.mixed())
    .min(1, "At least one language is required")
    .required("Languages are required"),
  // characters: yup
  //   .array()
  //   .of(yup.mixed())
  //   .min(1, "At least one language is required")
  //   .required("Languages are required"),
  title: languageSchema,
  // languages: yup
  //   .array()
  //   .of(yup.string())
  //   .min(1, "At least one language is required")
  //   .required("Languages are required"),
  releaseYear: yup
    .string()
    .required("Release year is required")
    .matches(/^\d{4}$/, "Release year must be a 4-digit number")
    .test("maxYear", "Release year cannot be in the future", (value) => {
      const currentYear = new Date().getFullYear();
      return parseInt(value, 10) <= currentYear;
    }),
  rentPrice: yup.string().required("Rent price is required"),
  sarRentPrice: yup.string().required("Rent price is required"),
  description: languageSchema,
  coverImage: yup.mixed().required("Cover image is required"),
  movie: fileSchema,
  trailer: fileSchema,
});
const editSchema = yup.object().shape({
  genres: yup
    .array()
    .of(yup.mixed())
    .min(1, "At least one language is required")
    .required("Languages are required"),
  // characters: yup
  //   .array()
  //   .of(yup.mixed())
  //   .min(1, "At least one language is required")
  //   .required("Languages are required"),
  title: languageSchema,
  releaseYear: yup
    .string()
    .required("Release year is required")
    .matches(/^\d{4}$/, "Release year must be a 4-digit number")
    .test("maxYear", "Release year cannot be in the future", (value) => {
      const currentYear = new Date().getFullYear();
      return parseInt(value, 10) <= currentYear;
    }),
  rentPrice: yup.string().required("Rent price is required"),
  sarRentPrice: yup.string().required("Rent price is required"),
  description: languageSchema,
});
const defaultCharacterValues = [
  {
    name: {
      en: "",
      ar: "",
      tr: "",
    },
    description: {
      en: "",
      ar: "",
      tr: "",
    },
    file: [],
    deleted: false,
    preview: [],
  },
];

const AddMovie = ({ versionId }) => {
  const [modalShow, setModalShow] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const seacrUrl = new URLSearchParams(location.search);
  const movieId: string = seacrUrl.get("key") || "";
  const [formObj, setformObj] = useState(defaultValues);
  const [charObj, setCharObj] = useState(defaultCharacterValues);
  const [delObj, setDelObj] = useState({
    trailerUrl: defaultFile,
    videoUrl: defaultFile,
  });
  const [errorObj, setErrorObj] = useState(defaultErrorValues);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [count, setCount] = useState(6);
  const [tab, setTab] = useState("en");
  const [avg, setAvg] = useState(0);
  const [folderId, setFolderId] = useState(null);
  console.log("releaseyesr", formObj.releaseYear);
  useEffect(() => {
    setFetching(true);
    getMovieById();
  }, []);
  useEffect(() => {
    const avg =
      (+formObj.trailerProgress.en +
        +formObj.trailerProgress.tr +
        +formObj.trailerProgress.ar +
        +formObj.movieProgress.en +
        +formObj.movieProgress.tr +
        +formObj.movieProgress.ar) /
      count;
    setAvg(avg);
  }, [formObj]);
  const getMovieById = async () => {
    try {
      const res = await getVersionDetailsService({ versionId });
      if (res.data.status) {
        const movie = res.data.movie;
        console.log(8999, movie);
        const obj = {
          genres: movie?.genres?.map((item) => ({
            label: item?.title?.en,
            value: item?._id,
          })),
          title: {
            en: movie.title.en,
            ar: movie.title.ar,
            tr: movie.title.tr,
          },
          releaseYear: movie.releaseYear,
          rentPrice: movie.rentPrice,
          sarRentPrice: movie.sarRentPrice,
          description: {
            en: movie.description.en,
            ar: movie.description.ar,
            tr: movie.description.tr,
          },
          trailerUrl: movie.trailerUrl,
          videoUrl: movie.videoUrl,
          coverImage: movie.coverImage,
          posterImage: movie.posterImage,
        };
        setformObj((prev) => ({
          ...prev,
          ...obj,
        }));
        const objToChar =
          movie?.characters?.length > 0
            ? movie?.characters?.map((char) => ({
                ...char,
                file: [],
                preview: [],
                deleted: false,
              }))
            : defaultCharacterValues;
        setCharObj(objToChar);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setFetching(false);
    }
  };
  const handleSelect = (key: any) => {
    setTab(key);
  };
  const handleEdit = async (e: any) => {
    e.preventDefault();
    setFetching(true);
    try {
      const errors: any = await validateFields(editSchema, formObj);
      if (Object.keys(errors).length > 0) {
        if (
          errors[`title.en`] ||
          errors[`description.en`] ||
          errors[`movie.en`] ||
          errors[`trailer.en`]
        ) {
          setTab("en");
        } else if (
          errors[`title.ar`] ||
          errors[`description.ar`] ||
          errors[`movie.ar`] ||
          errors[`trailer.ar`]
        ) {
          setTab("ar");
        } else if (
          errors[`title.tr`] ||
          errors[`description.tr`] ||
          errors[`movie.tr`] ||
          errors[`trailer.tr`]
        ) {
          setTab("tr");
        }
        return setErrorObj(errors);
      }
      const genres = formObj.genres.map((item: any) => item.value);
      let obj:any = {
        versionId,
        title: formObj.title,
        description: formObj.description,
        genres,
        releaseYear: formObj.releaseYear,
        rentPrice: formObj.rentPrice,
        sarRentPrice: formObj.sarRentPrice,
        posterImage: formObj.posterImage,
        coverImage: formObj.coverImage,
      };
      const [ten, tar, ttr, men, mar, mtr]: [any, any, any, any, any, any] =
        await Promise.allSettled([
          !formObj.trailerUrl.en &&
            handleUploadClick(
              formObj.trailer.en,
              folderId,
              formObj.title.en,
              formObj.description.en,
              setformObj,
              "trailerProgress",
              "en"
            ),
          !formObj.trailerUrl.ar &&
            handleUploadClick(
              formObj.trailer.ar,
              folderId,
              formObj.title.ar,
              formObj.description.ar,
              setformObj,
              "trailerProgress",
              "ar"
            ),
          !formObj.trailerUrl.tr &&
            handleUploadClick(
              formObj.trailer.tr,
              folderId,
              formObj.title.tr,
              formObj.description.tr,
              setformObj,
              "trailerProgress",
              "tr"
            ),
          !formObj.videoUrl.en &&
            handleUploadClick(
              formObj.movie.en,
              folderId,
              formObj.title.en,
              formObj.description.en,
              setformObj,
              "movieProgress",
              "en"
            ),
          !formObj.videoUrl.ar &&
            handleUploadClick(
              formObj.movie.ar,
              folderId,
              formObj.title.ar,
              formObj.description.ar,
              setformObj,
              "movieProgress",
              "ar"
            ),
          !formObj.videoUrl.tr &&
            handleUploadClick(
              formObj.movie.tr,
              folderId,
              formObj.title.tr,
              formObj.description.tr,
              setformObj,
              "movieProgress",
              "tr"
            ),
        ]);
        
      obj = {
        ...obj,
        videoUrl: {
          en: men.value
            ? extractMovieCode(men.value.uploadData.uri)
            : formObj.videoUrl.en,
          ar: mar.value
            ? extractMovieCode(mar.value.uploadData.uri)
            : formObj.videoUrl.ar,
          tr: mtr.value
            ? extractMovieCode(mtr.value.uploadData.uri)
            : formObj.videoUrl.tr,
        },
        trailerUrl: {
          en: ten.value
            ? extractMovieCode(ten.value.uploadData.uri)
            : formObj.trailerUrl.en,
          ar: tar.value
            ? extractMovieCode(tar.value.uploadData.uri)
            : formObj.trailerUrl.ar,
          tr: ttr.value
            ? extractMovieCode(ttr.value.uploadData.uri)
            : formObj.trailerUrl.tr,
        },
      };
      console.log(9999, obj);
      const formData = new FormData();
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (
            key === "videoUrl" ||
            key === "title" ||
            key === "description" ||
            key === "trailerUrl"
          ) {
            formData.append(key, JSON.stringify(obj[key]));
          } else if (Array.isArray(obj[key])) {
            obj[key].forEach(function (value) {
              formData.append(key, value);
            });
          } else {
            formData.append(key, obj[key]);
          }
        }
      }
      const result = await editRevisionService(formData);
      if (result.data.status) {
        setformObj(defaultValues);
        toast.success(result.data.message);
        navigate("/movies");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setFetching(false);
    }
  };

  return fetching ? (
    <Loader />
  ) : (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>Edit Movie</PageTitle>

      <AddForm
        lang={tab}
        formObj={formObj}
        setformObj={setformObj}
        errorObj={errorObj}
        setErrorObj={setErrorObj}
        defaultErrorValues={defaultErrorValues}
        movieId={movieId}
      />

      <Tabs
        activeKey={tab}
        id="uncontrolled-tab-example"
        className="my-5"
        onSelect={handleSelect}
      >
        <Tab
          eventKey="en"
          title={<div style={{ width: "6rem" }}>EN</div>}
        ></Tab>
        <Tab
          eventKey="ar"
          title={<div style={{ width: "6rem" }}>AR</div>}
        ></Tab>
        <Tab
          eventKey="tr"
          title={<div style={{ width: "6rem" }}>TR</div>}
        ></Tab>
      </Tabs>

      <AddLanguageForm
        lang={tab}
        formObj={formObj}
        setformObj={setformObj}
        errorObj={errorObj}
        setErrorObj={setErrorObj}
        defaultErrorValues={defaultErrorValues}
      />

      <AddMovieForm
        lang={tab}
        formObj={formObj}
        setformObj={setformObj}
        errorObj={errorObj}
        setErrorObj={setErrorObj}
        defaultErrorValues={defaultErrorValues}
        movieId={movieId}
        setDelObj={setDelObj}
      />

      <>
        {avg > 0 && (
          <div
            className="mt-5 progress h-6px"
            style={{ width: "300px", backgroundColor: "#d3d3d3" }}
          >
            <div
              className="progress-bar bg-primary"
              role="progressbar"
              style={{ width: avg + "%" }}
            ></div>
          </div>
        )}
        <Button className="mt-5 btn me-5" onClick={() => navigate(-1)}>
          Back
        </Button>
        <Button className="mt-5 btn" disabled={loading} onClick={handleEdit}>
          {loading ? "Updating" : "Update"}
        </Button>
      </>

      {/* <MailModal setShow={setModalShow} show={modalShow} /> */}
    </>
  );
};

export default AddMovie;
